import axios from "axios";

const api = axios.create({
  baseURL: `${process.env.REACT_APP_SERVER_URL}/api`,
  headers: {
    Authorization: `Bearer ${localStorage.getItem("idToken")}`,
  },
});

export const get_clean = (url, config = {}) => { return api.get(url, config); }
export const put_clean = (url, data = {}, config = {}) => { return api.put(url, data, config)};
export const post_clean = (url, data = {}, config = {}) => { return api.post(url, data, config)};

export const get = (organizationId, url, config = {}) => {
  return api.get(`/organizations/${organizationId}${url}`, config);
};

export const put = (organizationId, url, data = {}, config = {}) => {
  return api.put(`/organizations/${organizationId}${url}`, data, config);
};

export const post = (organizationId, url, data = {}, config = {}) => {
  return api.post(`organizations/${organizationId}${url}`, data, config);
};

export const del = (organizationId, url, config = {}) => {
  return api.delete(`/organizations/${organizationId}${url}`, config);
};

export const patch = (organizationId, url, data = {}, config = {}) => {
  return api.patch(`/organizations/${organizationId}${url}`, data, config);
}

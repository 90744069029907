import * as React from "react";
import { EmptyState, EmptyStateBody, EmptyStateHeader, EmptyStateIcon } from "@patternfly/react-core";
import { SearchIcon } from "@patternfly/react-icons";

export const NoResultsFoundComponent = ({ isFullHeight, icon = SearchIcon, title, message }) => {

  return (
    <EmptyState isFullHeight={isFullHeight === undefined ? true : isFullHeight}>
      <EmptyStateHeader
        titleText={title ? title : "No Results Found"}
        headingLevel="h4"
        icon={<EmptyStateIcon icon={icon} />}
      />
      <EmptyStateBody>
        { message ? message : "No results match the filter criteria. Clear all filters and try again." }
      </EmptyStateBody>
    </EmptyState>
  );

};
import * as React from "react";
import { Button, Modal, ModalVariant } from "@patternfly/react-core";

export const ConfirmationModal = ({ title, message, isOpen, isDelete, onClose, onAction }) => {

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={title}
      titleIconVariant="warning"
      variant={ModalVariant.small}
      actions={[
        <Button key="action" variant={isDelete ? "danger" : "primary"} onClick={onAction}>
          {isDelete ? "Delete" : "Confirm"}
        </Button>,
        <Button key="cancel" variant="link" onClick={onClose}>
          Cancel
        </Button>
      ]}
    >
      { message }
    </Modal>
  );
};
import * as React from "react"
import { AlertGroup, PageSection, PageSectionVariants } from "@patternfly/react-core";

import { NotificationContext } from "./common/contexts/NotificationContext";

export const Alerts = () => {

  const { alerts, maxDisplayed, overflowMessage, onAlertGroupOverflowClick } = React.useContext(NotificationContext);

  return (
    <PageSection variant={PageSectionVariants.default} style={{ height: '0px', padding: '0px' }}>
      <AlertGroup isToast isLiveRegion onOverflowClick={onAlertGroupOverflowClick} overflowMessage={overflowMessage}>
        {
          alerts.slice(0, maxDisplayed) 
        }
      </AlertGroup>
    </PageSection>
  );
  
};
import * as React from "react";
import { Bullseye } from "@patternfly/react-core";
import { DataListAction, DataListCell, DataListItem, DataListItemCells, DataListItemRow, Dropdown, DropdownItem, DropdownList } from "@patternfly/react-core";
import { Icon } from "@patternfly/react-core";
import { MenuToggle } from "@patternfly/react-core";
import { Text, TextContent, TextVariants } from "@patternfly/react-core";
import { EditIcon, EllipsisVIcon, EnvelopeIcon } from "@patternfly/react-icons";
import { AvatarComponent } from "../../components/AvatarComponent";
import { ManagerTextComponent } from "../../components/ManagerTextComponent";
import { OrganizationTextComponent } from "../../components/OrganizationTextComponent";

export const UserDataListItem = ({ user, isOpen, setIsOpen, onSelect, onToggle, onView, onEdit, onDelete }) => {

  return (
    <DataListItem aria-labelledby={user.id}>
      <DataListItemRow>
        <DataListItemCells
          dataListCells={[
            <DataListCell key="icon" isFilled={false}>
              <Bullseye>
                <AvatarComponent user={user} size="sm" />
              </Bullseye>
            </DataListCell>,
            <DataListCell key="name" isFilled>
              <TextContent>
                <Text component={TextVariants.p}>
                  { user.first_name + ' ' + user.surname }
                </Text>
              </TextContent>
            </DataListCell>,
            <DataListCell key="email" isFilled>
              <TextContent><Text component={TextVariants.small}><Icon><EnvelopeIcon /></Icon>&nbsp;{ user.email_address }</Text></TextContent>
            </DataListCell>,
            <DataListCell key="manager" isFilled>
              <ManagerTextComponent user={user} showIcon />
            </DataListCell>,
            <DataListCell key="organization" isFilled>
              <OrganizationTextComponent user={user} showIcon />
            </DataListCell>,
            <DataListCell key="role" isFilled>
              <TextContent><Text component={TextVariants.p}>{ user.user_role }</Text></TextContent>
            </DataListCell>
          ]}
        />
        <DataListAction isPlainButtonAction>
          <Dropdown
            popperProps={{ position: 'right' }}
            onOpenChange={(isOpen) => { if (isOpen) setIsOpen(user.id); else setIsOpen(0) }}
            onSelect={ onSelect }
            isOpen={(isOpen === user.id)}
            toggle={(toggleRef) => (
              <MenuToggle
                ref={toggleRef}
                isExpanded={isOpen === user.id}
                onClick={() => onToggle(user.id)}
                variant="plain"
                aria-label="Date List Item Action"
              >
                <EllipsisVIcon aria-hidden="true" />
              </MenuToggle>
            )}
          >
            <DropdownList>
              {/*
              <DropdownItem key="view" icon={<EyeIcon />} onClick={() => onView(user.id)}>View</DropdownItem>
              */}
              <DropdownItem key="edit" icon={<EditIcon />} onClick={() => onEdit(user.id)}>Edit</DropdownItem>
              {/*
              <Divider key="sep1"></Divider>
              <DropdownItem key="delete" icon={<TimesIcon />} onClick={() => onDelete(user.id)}>Delete</DropdownItem>
              */}
            </DropdownList>
          </Dropdown>
        </DataListAction>
      </DataListItemRow>
    </DataListItem>
  );

};

import * as React from "react";
import { Alert, Button, Form, FormAlert, FormGroup, FormHelperText, HelperText, HelperTextItem, Modal, ModalVariant, TextInput } from "@patternfly/react-core";

import { del, post, put } from "../../api/api";
import { NotificationContext } from "../../common/contexts/NotificationContext";
import { localLog } from "../../utils/Utilities";

export const Permission = ({ isOpen, onClose, permission, organizationId, action, onRefresh }) => {

  const { addNotification } = React.useContext(NotificationContext);

  const [debug] = React.useState(false);
  const [formData, setFormData] = React.useState(null);
  const [formErrors, setFormErrors] = React.useState({});

  React.useEffect(() => {
    setFormData(permission);
    setFormErrors({});
  }, [permission]);

  const onDelete = async () => {
    try {
      const response = await del(organizationId, `/permissions/deletePermission/${permission.id}`);
      addNotification({ variant: 'success', title: 'Permission Deleted', description: 'The permission has been deleted successfully.' });
      onRefresh(response.data);
    } catch (error) {
      console.error(JSON.stringify(error));
      addNotification({ variant: 'error', title: 'Permission Error', description: 'An error occurred while trying to delete the permission.' });
    }
  };

  const onSave = async () => {
    const errors = {};
    if (formData?.name === "")
      errors.name = "Name is required";
    if (formData?.description === "")
      errors.description = "Description is required";
    setFormErrors(errors);
    if (Object.keys(errors).length > 0) {
      return;
    }
    try {
      const permissionData = {
        name: formData.name,
        description: formData.description,
      };
      if (permission) {
        // Update permission
        const response = await put(organizationId, `/permissions/updatePermission/${permission.id}`, permissionData);
        addNotification({ variant: 'success', title: 'Permission Updated', description: 'The permission has been updated successfully.' });
        onRefresh(response.data);
      } else {
        // Create new permission
        if (debug) localLog("Create Permission: " + JSON.stringify(permissionData));
        const response = await post(organizationId, `/permissions/createPermission/`, permissionData);
        addNotification({ variant: 'success', title: 'Permission Created', description: 'The permission has been created successfully.' });
        onRefresh(response.data);
      }
      onClose();
    } catch (error) {
      console.error(JSON.stringify(error));
      addNotification({ variant: 'error', title: 'Permission Error', description: 'An error occurred while trying to update the permission.' });
    }
  };

  return (
    <Modal 
      aria-label="Permission Modal"
      variant={ModalVariant.medium}
      title={action === "delete" ? "Delete Permission" : permission ? "Edit Permission" : "Add Permission"} 
      isOpen={isOpen} 
      onClose={onClose}
      actions={
        action === "delete"
        ? [
            <Button key="delete" variant="danger" form="permission-form" onClick={onDelete}>Delete</Button>,
            <Button key="cancel" variant="secondary" onClick={onClose}>Cancel</Button>
          ]
        : [
          <Button key="save" variant="primary" form="permission-form" onClick={onSave}>Save</Button>,
          <Button key="cancel" variant="secondary" onClick={onClose}>Cancel</Button>
        ]
      }
    >
      <Form id="permission-form" isHorizontal>
        {
          formErrors && Object.keys(formErrors).length > 0 && (
            <FormAlert>
              <Alert variant="danger" title="Fill out all required fields before continuing." isInline />
            </FormAlert>
          )
        }
        <FormGroup label="Name" isRequired fieldId="name">
          <TextInput id="name" placeholder="Enter the name of the permission" type="text" validated={formErrors?.name ? "error" : "default"} value={formData?.name || ""} isRequired onChange={(e, value) => setFormData({ ...formData, name: value })}></TextInput>
          <FormHelperText><HelperText><HelperTextItem variant={formErrors?.name ? "error" : "default"}>{ formErrors?.name }</HelperTextItem></HelperText></FormHelperText>
        </FormGroup>
        <FormGroup label="Description" isRequired fieldId="description">
          <TextInput id="description" placeholder="Enter a description for the permission" type="text" value={formData?.description || ""} onChange={(e, value) => setFormData({ ...formData, description: value })} />
          <FormHelperText><HelperText><HelperTextItem variant={formErrors?.description ? "error" : "default" }>{ formErrors?.description }</HelperTextItem></HelperText></FormHelperText>
        </FormGroup>
      </Form>
    </Modal>
  );
};

import * as Yup from "yup";

export const validationSchema = (isExternal) => {
  let schema = Yup.object().shape({
    isExternal: Yup.boolean().required("Shoutout type is required"),
    selectedUsers: Yup.array()
      .of(Yup.object().required("This field is required"))
      .min(1, "Select at least 1 user"),
    selectedCategories: Yup.array()
      .of(Yup.object().required("Select at least 1 category"))
      .min(1, "Select at least 1 category")
      .max(3, "You can only select a maximum of 3 categories"),
    message: Yup.string()
      .min(50, "Message must be at least 50 characters")
      .max(255, "Message can only be 255 characters")
      .required("Message is required"),
  });

  // If isExternal is true, add validation for externalData
  if (isExternal) {
    schema = schema.shape({
      extUserName: Yup.string().required("Name is required"),
      extUserSurname: Yup.string().required("Surname is required"),
      extUserOrganization: Yup.string().required("Organization is required"),
    });
  }

  return schema;
};

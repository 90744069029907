import * as React from "react";
import { EmptyState, EmptyStateBody, EmptyStateHeader, EmptyStateIcon, Text, TextContent } from "@patternfly/react-core";
import { ExclamationCircleIcon } from "@patternfly/react-icons";

export const ErrorStateComponent = (error) => {

  return (
    <EmptyState style={{ minHeight: '50vh' }}>
      <EmptyStateHeader
        titleText="Unable to Connect"
        headingLevel="h4"
        icon={<EmptyStateIcon icon={ExclamationCircleIcon} color="red" />}
      />
      <EmptyStateBody>
        <TextContent>
        {
          error ? 
          (<Text>{error?.error?.message}</Text>)
          :
          (<Text>Check your connection and reload the page.</Text>)
        }
        </TextContent>
      </EmptyStateBody>
    </EmptyState>
  );

};
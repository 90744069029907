//
// Utilities.jsx
//
// Collection of utility functions used in the application
//

// localLog(message)
// If the application is running on localhost, only then print the message to the console
export function localLog(message) {
  window.location.href.indexOf("localhost") > -1 && console.log(message);
}

// payloadLog(message)
// If the application is running on localhost, only then print the payload to the console
export function payloadLog(payload) {
  window.location.href.indexOf("localhost") > -1 && console.log(JSON.stringify(payload, null, 2));
}

export function hasReportsPermission(user) {
  if (!user) {
    return false;
  }
  if (!user.permissions) {
    return false;
  }
  let permission = user.permissions.find((obj) => obj.name === "Reports");
  if (permission)
    return true;
};

export function hasAdminPermission(user) {
  if (!user) {
    return false;
  }
  if (!user.permissions) {
    return false;
  }
  let permission = user.permissions.find((obj) => obj.name === 'Admin');
  if (permission)
    return true;
};

export function hasSuperAdminPermission(user) {
  if (!user) {
    return false;
  }
  if (!user.permissions) {
    return false;
  }
  let permission = user.permissions.find((obj) => obj.name === 'Super Admin');
  if (permission)
    return true;
}

export function getCurrentCompetition(competitions) {
  const current = new Date();
  const sorted = competitions.sort(multipleSort("date_from", "date_to"));
  let competition = sorted.find(item => new Date(item.date_from.substring(0, 10) + " 00:00:00") <= current && new Date(item.date_to.substring(0, 10) + " 23:59:59") >= current);
  if (competition === null) {
    competition = { "period_name": "", "date_to": current, "date_from": current }
  }
  return competition;
};

export function dynamicSort(property) {
  var sortOrder = 1;
  if (property[0] === "-") {
    sortOrder = -1;
    property = property.substr(1);
  }
  return function(a, b) {
    /*
     * next line works with strings and numbers
     * and you may want to customize it to your needs
     */
    var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
    return result * sortOrder;
  }
};

export function multipleSort() {
  /*
   * save the arguments object as it will be overwritten
   * note that arguments object is an array-like object
   * consisting of the name of the properties to sort by
   */
  var props = arguments;
  return function(obj1, obj2) {
    var i = 0;
    var result = 0;
    var numberOfProperties = props.length;
    /* 
     * try getting a different result from 0 (equal)
     * as long as we have extra properties to compare
     */
    while (result === 0 && i < numberOfProperties) {
      result = dynamicSort(props[i])(obj1, obj2);
      i++;
    }
    return result;
  }
}
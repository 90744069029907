import * as React from "react";
import { useLocation } from "react-router-dom";
import { Button, ListItem, ListVariant } from "@patternfly/react-core";
import { LoginFooterItem, /*LoginMainFooterBandItem,*/ LoginMainFooterLinksItem, LoginPage } from "@patternfly/react-core";
import { ExclamationCircleIcon } from "@patternfly/react-icons";
import "./Login.css";

import { useAuth } from "../common/contexts/AuthContext";
import { localLog } from "../utils/Utilities";
import { LoginForm } from "./LoginForm";
// import AltronDigitalBusiness from "../assets/brands/Altron Digital Business Logo Light.svg";
import ShoutOut from "../assets/brands/ShoutOut Light.png";

const Login = () => {

  const auth = useAuth();
  const location = useLocation();

  // const [searchParams ] = useSearchParams();
  // const id_token = useSearchParams("id_token");
  // const access = useParams("access_token");

  const [debug] = React.useState(false);
  const [showHelperText, setShowHelperText] = React.useState(false);
  const [username, setUsername] = React.useState("");
  const [isValidUsername, setIsValidUsername] = React.useState(true);
  // const [password, setPassword] = React.useState("");
  // const [isValidPassword, setIsValidPassword] = React.useState(true);
  const [id] = React.useState(new URLSearchParams(location.search).get("id_token"));
  const [access] = React.useState(new URLSearchParams(location.search).get("access_token"));
  // const [isRememberMeChecked, setIsRememberMeChecked] = React.useState(false);

  React.useEffect(() => {
    // if (debug) localLog("Login useEffect: id: " + id);
    if (id && access) {
      handleSSOLogin();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSSOLogin = () => {
    if (id === null) {
      auth.processSSOCallback();
    } else {
      auth.processSSOCallback(id, access);
    }
  };

  const onChangeUsername = (e, value) => {
    setUsername(value);
  };

  // const onChangePassword = (e, value) => {
  //   setPassword(value);
  // };

//  const onRememberMeClick = () => {
//    setIsRememberMeChecked(!isRememberMeChecked);
//  };

  const onLoginButtonClick = async (e) => {
    try {
      e.preventDefault();
      setIsValidUsername(!!username);
      // setIsValidPassword(!!password);
      const response = await auth.login(username);
      if (debug) localLog("Login response: " + JSON.stringify(response, null, 2));
    } catch (err) {
      console.error(JSON.stringify(err));
      setShowHelperText(true);
    }
  };

  const singleSignOnLogin = (
    <React.Fragment>
      <LoginMainFooterLinksItem style={{ minWidth: '100%' }}>
        <Button style={{ minWidth: '100%' }} onClick={(e) => {e.preventDefault(); handleSSOLogin();}}>Single-Sign-On</Button>
      </LoginMainFooterLinksItem>
    </React.Fragment>
  );

  // const forgottenCredentials = (
  //   <LoginMainFooterBandItem>
  //     <a href="https://www.altron.com">Forgot Credentials ?</a>
  //   </LoginMainFooterBandItem>
  // );

  const footerItems = (
    <React.Fragment>
      <ListItem>
        <LoginFooterItem href="https://www.altron.com/terms-conditions-2024">Terms and Conditions</LoginFooterItem>
      </ListItem>
      <ListItem>
        <LoginFooterItem href="https://www.altron.com/privacy-statement">Privacy Policy</LoginFooterItem>
      </ListItem>
      <ListItem>
        {/* need support email here */}
        <LoginFooterItem href="mailto:shoutoutservicerequests@altron.com">Contact Support</LoginFooterItem>
      </ListItem>
    </React.Fragment>
  );

  const loginForm = (
    <LoginForm
      showHelperText={showHelperText}
      helperText="Invalid Login Credentials"
      helperTextIcon={<ExclamationCircleIcon />}
      usernameLabel="E-mail Address"
      usernameValue={username}
      onChangeUsername={onChangeUsername}
      isValidUsername={isValidUsername}
      isShowPassword={false}
      passwordLabel="Password"
      // passwordValue={password}
      // onChangePassword={onChangePassword}
      // isValidPassword={isValidPassword}
      // rememberMeLabel="Keep me logged in for 30 days"
      // isRememberMeChecked={isRememberMeChecked}
      // onChangeRememberMe={onRememberMeClick}
      onLoginButtonClick={onLoginButtonClick}
      loginButtonLabel="Log In"
    />
  );

  return (
    <LoginPage
      footerListVariants={ListVariant.inline}
      brandImgSrc={ShoutOut}
      brandImgAlt="ShoutOut Logo"
      backgroundImgSrc="/adbbg-login.jpg"
      footerListItems={footerItems}
//      textContent="This is placeholder text only.  Use this area to place any information or introductory message about your application that may be relevant to users."
      loginTitle="Log in to the application"
      loginSubtitle="Enter your login credentials or just click the Single-Sign-On button"
//      forgotCredentials={forgottenCredentials}
      socialMediaLoginContent={singleSignOnLogin}
      socialMediaLoginAriaLabel="Single-Sign-On"
    >
      {loginForm}
    </LoginPage>
  );
};

export { Login };

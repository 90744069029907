import * as React from "react";
import { Alert, AlertActionCloseButton } from "@patternfly/react-core";

import { get } from "../../api/api";
import { localLog } from "../../utils/Utilities";
import { OrganizationContext } from "./OrganizationContext";
import { UserContext } from "./UserContext";

const NotificationContext = React.createContext();

const NotificationProvider = ({ children }) => {

  const maxDisplayedAlerts = 3;
  const alertTimeout = 8000;

  const organization = React.useContext(OrganizationContext);
  const user = React.useContext(UserContext);

  const [debug] = React.useState(false);
  const [isDrawerExpanded, setIsDrawerExpanded] = React.useState(false);
  const [overflowMessage, setOverflowMessage] = React.useState("");
  const [maxDisplayed] = React.useState(maxDisplayedAlerts);
  const [alerts, setAlerts] = React.useState([]);
  const [notifications, setNotifications] = React.useState([]);
  const [isNotificationsLoading, setIsNotificationsLoading] = React.useState(false);

  React.useEffect(() => {
    if (debug) localLog("Notifications: " + JSON.stringify(notifications, null, 2));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifications]);

  React.useEffect(() => {
    setOverflowMessage(buildOverflowMessage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maxDisplayed, notifications, alerts]);

  const fetchNotifications = async () => {
    setIsNotificationsLoading(true);
    try {
      const response = await get(organization.id, "/notifications/users/" + user.id);
      setNotifications(response.data);
      setIsNotificationsLoading(false);
    } catch (error) {
      console.error(JSON.stringify(error));
    }
  };

  React.useEffect(() => {
    fetchNotifications();
    // eslint-disable-next-line
  }, []);

  const addNotification = ({ variant, title, description }) => {
    const srTitle = title;
    const key = getUniqueId();
    const timestamp = getTimeCreated();

    setNotifications((prevNotifications) => [
      { title, srTitle, variant, key, timestamp, description, isNotificationRead: false },
      ...prevNotifications
    ]);

    if (!isDrawerExpanded) {
      setAlerts((prevAlerts) => [
        <Alert
          variant={variant}
          title={title}
          timeout={alertTimeout}
          onTimeout={() => removeAlert(key)}
          isLiveRegion
          actionClose={
            <AlertActionCloseButton title={title} variantLabel={`${variant} alert`} onClose={() => removeAlert(key)} />
          }
          key={key}
          id={key.toString()}
        >
          <p>{description}</p>
        </Alert>,
        ...prevAlerts
      ]);
    }
  };

  const addNewNotification = (variant) => {
    const variantFormatted = variant.charAt(0).toUpperCase() + variant.slice(1);
    const title = variantFormatted + ' alert notification';
    const srTitle = variantFormatted + " alert";
    const description = variantFormatted + " alert notification description";
    const key = getUniqueId();
    const timestamp = getTimeCreated();

    setNotifications((prevNotifications) => [
      { title, srTitle, variant, key, timestamp, description, isNotificationRead: false },
      ...prevNotifications
    ]);

    if (!isDrawerExpanded) {
      setAlerts((prevAlerts) => [
        <Alert
          variant={variant}
          title={title}
          timeout={alertTimeout}
          onTimeout={() => removeAlert(key)}
          isLiveRegion
          actionClose={
            <AlertActionCloseButton title={title} variantLabel={`${variant} alert`} onClose={() => removeAlert(key)} />
          }
          key={key}
          id={key.toString()}
        >
          <p>{description}</p>
        </Alert>,
        ...prevAlerts
      ]);
    }
  };

  const buildOverflowMessage = () => {
    const overflow = alerts.length - maxDisplayed;
    if (overflow > 0 && maxDisplayed > 0) {
      return `View ${overflow} more notification(s) in notification drawer`;
    }
    return "";
  };

  const getUniqueId = () => new Date().getTime();

  const getTimeCreated = () => {
    const dateCreated = new Date();
    return (
      dateCreated.toDateString() + ' at ' + ('00' + dateCreated.getHours().toString()).slice(-2) + ':' + ('00' + dateCreated.getMinutes().toString()).slice(-2)
    );
  };

  const removeAlert = (key) => {
    setAlerts((prevAlerts) => prevAlerts.filter((alert) => alert.props.id !== key.toString()));
  };

  const removeAllAlerts = () => {
    setAlerts([]);
  };

  const onAlertGroupOverflowClick = () => {
    removeAllAlerts();
    setIsDrawerExpanded(true);
  };

  return (
    <NotificationContext.Provider
      value={{ alerts, setAlerts, notifications, setNotifications, maxDisplayed, overflowMessage, isNotificationsLoading, onAlertGroupOverflowClick, addNotification, addNewNotification, removeAlert }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export { NotificationContext, NotificationProvider };

// export const useNotification = () => {
//   const context = React.useContext(NotificationContext);
//   if (!context) {
//     throw new Error("useNotification must be used within an NotificationProvider");
//   }
//   return context;
// };
import * as React from "react";
import { DataList } from "@patternfly/react-core";
import { ShoutOutDataListItem } from "./ShoutOutDataListItem";

import { localLog } from "../../utils/Utilities";

export const ShoutOutDataList = ({ user, organization, shoutouts, setShoutouts }) => {

  return (
    <DataList aria-label="ShoutOuts">
      {
        shoutouts.map((shoutout) => {
          shoutout.attachment_url && localLog("shoutout.attachment_url: " + shoutout.attachment_url);
          return (
            <ShoutOutDataListItem key={shoutout.id} user={user} organization={organization} shoutout={shoutout} setShoutouts={setShoutouts} />
          )
        })
      }
    </DataList>
  );

};
import * as React from "react";
import { Alert, Button } from "@patternfly/react-core";
import { DualListSelector, DualListSelectorList, DualListSelectorListItem, DualListSelectorPane } from "@patternfly/react-core";
import { DualListSelectorControl, DualListSelectorControlsWrapper } from "@patternfly/react-core";
import { Form, FormAlert, FormGroup, FormHelperText } from "@patternfly/react-core";
import { HelperText, HelperTextItem, Modal, ModalVariant, TextInput } from "@patternfly/react-core";
import { AngleDoubleLeftIcon, AngleLeftIcon, AngleDoubleRightIcon, AngleRightIcon, ExclamationCircleIcon, FlagIcon } from "@patternfly/react-icons";

import { del, get, post, put } from "../../api/api";
import { NotificationContext } from "../../common/contexts/NotificationContext";

export const Role = ({ isOpen, onClose, role, organizationId, action = "update", onRefresh }) => {

  const { addNotification } = React.useContext(NotificationContext);

  const [formData, setFormData] = React.useState(role);
  const [formErrors, setFormErrors] = React.useState(undefined);
  const [allPermissions, setAllPermissions] = React.useState([]);
  const [selPermissions, setSelPermissions] = React.useState([]);

  const fetchAllPermissions = React.useCallback( async() => {
    try {
      const response = await get(organizationId, `/permissions/getPermissions`);
      // add isVisible and selected to each object in array
      response.data.forEach(obj => { obj.text = obj.name; obj.isVisible = true; obj.selected = false });
      setAllPermissions(response.data);
    } catch (error) {
      console.error(JSON.stringify(error));
      setFormErrors({ permissions: "Unable to fetch all permissions." });
    }
  }, [organizationId]);

  const fetchSelPermissions = React.useCallback( async (id) => {
    var available = [];
    var response = [];
    try {
      available = await get(organizationId, `/permissions/getPermissions`);
      // add isVisible and selected to each object in array
      available.data.forEach(obj => { obj.text = obj.name; obj.isVisible = true; obj.selected = false });
    } catch (error) {
      console.error(JSON.stringify(error));
      setFormErrors({ permissions: "Unable to fetch all permissions." });
    }
    try {
      response = await get(organizationId, `/role/getRoleWithPermissionsById/${id}`);
      // add isVisible and selected to each object in array
      response.data.permissions.forEach(obj => { obj.text = obj.name; obj.isVisible = true; obj.selected = false; });
      // remove current permissions from list of permissions
      var list = available.data;
      for (var i = 0; i < response.data.permissions.length; i++) {
        // eslint-disable-next-line no-loop-func
        var exist = list.findIndex((option) => { return option.id === response.data.permissions[i].id})
        if (exist > -1) list.splice(exist, 1);
      }
      setAllPermissions(list);
      setSelPermissions(response.data.permissions);
    } catch (error) {
      console.error(JSON.stringify(error));
      setFormErrors({ permissions: "Unable to fetch role's permissions" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationId]);

  React.useEffect(() => {

    const fetchFirst = async (id) => {
//      fetchAllPermissions();
      fetchSelPermissions(id);
    }

    setFormData(role);
    setFormErrors(undefined);
    if (role) {
      fetchFirst(role?.id);
    }
  }, [role, fetchAllPermissions, fetchSelPermissions]);

  const validateForm = () => {
    const errors = {};
    if (!formData || !formData.name || formData.name.trim() === "") {
      errors.name = "Name is required";
    }
    if (!formData || !formData.description || formData.description.trim() === "") {
      errors.description = "Description is required";
    }
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
    }
    return (Object.keys(errors).length === 0);
  };

  const onDelete = async () => {
    try {
      const response = await del(organizationId, `/role/deleteRole/${role.id}`);
      addNotification({ variant: 'success', title: 'Role Deleted', description: 'The role has been deleted successfully.' });
      onRefresh(response.data);
    } catch (error) {
      console.error(JSON.stringify(error));
      addNotification({ variant: 'error', title: 'Role Error', description: 'An error occurred while trying to delete the role.' });
    }
  };

  const onSave = async () => {
    if (validateForm()) {
      try {
        const roleData = {
          name: formData.name,
          description: formData.description,
          organization_id: organizationId,
          permissions: selPermissions.map(obj => obj.id)
        };
        const perms = {
          "permissionIds": selPermissions.map(obj => obj.id)
        }
        if (role) {
          // Update role
          const response = await put(organizationId, `/role/updateRole/${role.id}`, roleData);
          onRefresh(response.data);
          if (selPermissions.length === 0) {
            await put(organizationId, `/rolepermissions/updatePermsissionForRole/${role.id}`, { "permissionIds": perms });
          }
          addNotification({ variant: 'success', title: 'Role Updated', description: 'The role has been updated successfully.' });
        } else {
          // Create new role
          const response = await post(organizationId, `/role/createRole/`, roleData);
          onRefresh(response.data);
          addNotification({ variant: 'success', title: 'Role Updated', description: 'The role has been created successfully.' });
        }
        onClose();
      } catch (error) {
        console.error(JSON.stringify(error));
      }
    }
  };

  const onOptionSelect = (event, index, isChosen) => {
    if (isChosen) {
      const newChosen = [...selPermissions];
      newChosen[index].selected = !selPermissions[index].selected;
      setSelPermissions(newChosen);
    } else {
      const newAvailable = [...allPermissions];
      newAvailable[index].selected = !allPermissions[index].selected;
      setAllPermissions(newAvailable);
    }
  };

  const onMoveSelected = (fromAvailable) => {
    const sourceOptions = fromAvailable ? allPermissions : selPermissions;
    const destinationOptions = fromAvailable ? selPermissions : allPermissions;
    for (let i = 0; i < sourceOptions.length; i++) {
      const option = sourceOptions[i];
      if (option.selected && option.isVisible) {
        sourceOptions.splice(i, 1);
        destinationOptions.push(option);
        option.selected = false;
        i--;
      }
    }
    if (fromAvailable) {
      setAllPermissions([...sourceOptions]);
      setSelPermissions([...destinationOptions]);
    } else {
      setSelPermissions([...sourceOptions]);
      setAllPermissions([...destinationOptions]);
    }
  };

  const onMoveAll = (fromAvailable) => {
    if (fromAvailable) {
      setSelPermissions([...allPermissions.filter((option) => option.isVisible), ...selPermissions]);
      setAllPermissions([...allPermissions.filter((option) => !option.isVisible)]);
    } else {
      setAllPermissions([...selPermissions.filter((option) => option.isVisible), ...allPermissions]);
      setSelPermissions([...selPermissions.filter((option) => !option.isVisible)]);
    }
  };

  return (
    <Modal
      aria-label="Role Modal"
      variant={ModalVariant.medium}
      title={<><FlagIcon /> {action === "delete" ? "Delete Role" : role ? "Edit Role" : "Add Role"}</>} 
      isOpen={isOpen} 
      onClose={onClose}
      actions={
        [
          action === "delete"
          ? <Button key="delete" variant="danger" form="role-form" onClick={onDelete}>Delete</Button>
          : <Button key="save" variant="primary" form="role-form" onClick={onSave}>Save</Button>
          ,
          <Button key="cancel" variant="secondary" onClick={onClose}>Cancel</Button>
        ]
      }
    >
      <Form id="role-form" isHorizontal>
        { formErrors && (<FormAlert><Alert variant="danger" title="Fill out all required fields before continuing." isInline /></FormAlert>) }
        <FormGroup label="Name" isRequired fieldId="name">
          <TextInput id="name" placeholder="Enter the name of the role" type="text" value={formData?.name} isRequired onChange={(e, value) => setFormData({ ...formData, name: value })}></TextInput>
          { formErrors && (<FormHelperText><HelperText><HelperTextItem icon={<ExclamationCircleIcon />} variant={"error"}>{ formErrors.name }</HelperTextItem></HelperText></FormHelperText>) }
        </FormGroup>
        <FormGroup label="Description" isRequired fieldId="description">
          <TextInput id="description" placeholder="Enter a description for the role" type="text" value={formData?.description} onChange={(e, value) => setFormData({ ...formData, description: value })} />
          { formErrors && (<FormHelperText><HelperText><HelperTextItem icon={<ExclamationCircleIcon />} variant={"error"}>{ formErrors.description }</HelperTextItem></HelperText></FormHelperText>) }
        </FormGroup>
        <FormGroup label="Permissions" fieldId="permissions">
          <DualListSelector id="permissions">
            <DualListSelectorPane 
              title="Available Permissions"
              status={`${allPermissions.filter((option) => option.selected && option.isVisible).length} of ${allPermissions.filter((option) => option.isVisible).length} permissions selected`}
            >
              <DualListSelectorList>
                {
                  allPermissions.map((option, index) =>
                    option.isVisible
                    ? (
                      <DualListSelectorListItem
                        key={option.id}
                        id={`available-${option.id}`}
                        isSelected={option.selected}
                        onOptionSelect={(e) => onOptionSelect(e, index, false)}
                      >
                        { option.name }
                      </DualListSelectorListItem>
                    )
                    : null
                  )
                }
              </DualListSelectorList>
            </DualListSelectorPane>
            <DualListSelectorControlsWrapper>
              <DualListSelectorControl aria-label="Add Selected" isDisabled={!allPermissions.some((option) => option.selected)} tooltipContent="Add Selected" tooltipProps={{ position: 'top', 'aria-live': 'off' }} onClick={() => onMoveSelected(true)}>
                <AngleRightIcon />
              </DualListSelectorControl>
              <DualListSelectorControl aria-label="Add All" isDisabled={allPermissions.length === 0} tooltipContent="Add All" tooltipProps={{ position: 'right', 'aria-live': 'off' }} onClick={() => onMoveAll(true)}>
                <AngleDoubleRightIcon />
              </DualListSelectorControl>
              <DualListSelectorControl aria-label="Remove All" isDisabled={selPermissions.length === 0} tooltipContent="Remove All" tooltipProps={{ position: 'left', 'aria-live': 'off' }} onClick={() => onMoveAll(false)}>
                <AngleDoubleLeftIcon />
              </DualListSelectorControl>
              <DualListSelectorControl aria-label="Remove Selected" isDisabled={!selPermissions.some((option) => option.selected)} tooltipContent="Remove Selected" tooltipProps={{ position: 'bottom', 'aria-live': 'off' }} onClick={() => onMoveSelected(false)}>
                <AngleLeftIcon />
              </DualListSelectorControl>
            </DualListSelectorControlsWrapper>
            <DualListSelectorPane isChosen status={`${selPermissions.filter((option) => option.selected && option.isVisible).length} of ${selPermissions.filter((option) => option.isVisible).length} permissions selected`} title="Chosen Permissions">
              <DualListSelectorList>
                {
                  selPermissions.map((option, index) =>
                    option.isVisible
                    ? (
                      <DualListSelectorListItem
                        key={option.id}
                        id={`selected-${option.id}`}
                        isSelected={option.selected}
                        onOptionSelect={(e) => onOptionSelect(e, index, true)}
                      >
                        { option.name }
                      </DualListSelectorListItem>
                    )
                    : null
                  )
                }
              </DualListSelectorList>
            </DualListSelectorPane>
          </DualListSelector>
        </FormGroup>
      </Form>
    </Modal>
  );
};

import * as React from "react";
import { Route, Routes } from "react-router-dom";

import { NotFound } from "./pages/NotFound";
import { SendShoutOut } from "./pages/SendShoutOut";
import { UserProfile } from "./pages/UserProfile";
import { ShoutOut } from "./pages/shoutouts/ShoutOut";
import { ShoutOuts } from "./pages/shoutouts/ShoutOuts";
import { Leaderboard } from "./pages/Leaderboard";
import { Categories } from "./pages/administration/Categories";
import { Competitions } from "./pages/administration/Competitions";
import { Departments } from "./pages/administration/Departments";
import { Organizations } from "./pages/administration/Organizations";
// import { OrganizationConfig } from "./pages/administration/OrganizationConfig";
import { Permissions } from "./pages/administration/Permissions";
import { Roles } from "./pages/administration/Roles";
import { Users } from "./pages/administration/Users";

export function Routing({ userProfileData, organization }) {

  return (
    <Routes>
      <Route key={"profile"} exact path="/me" element={<UserProfile user={userProfileData} organization={organization} />} />
      <Route key={"home"} path="/" element={<ShoutOuts user={userProfileData} />} />
      <Route key={"shoutout"} exact path="/shoutouts/:id" element={<ShoutOut />} />
      <Route key={"sendshoutout"} exact path="/send" element={<SendShoutOut userProfileData={userProfileData} />} />
      <Route key={"shoutoutsFromMe"} exact path="/shoutouts/from" element={<ShoutOuts key={"shoutoutsFromMe"} user={userProfileData} shoutoutsFromMe={true} />} />
      <Route key={"myshoutouts"} exact path="/shoutouts/to" element={<ShoutOuts key={"myshoutouts"} user={userProfileData} shoutoutsToMe={true} />} />
      <Route key={"leaderboard"} exact path="/leaderboard" element={<Leaderboard user={userProfileData} />} />
      <Route key={"categories"} exact path="/administration/categories" element={<Categories />} />
      <Route key={"competitions"} exact path="/administration/competitions" element={<Competitions />} />
      <Route key={"departments"} exact path="/administration/departments" element={<Departments />} />
      <Route key={"organizations"} exact path="/administration/organizations" element={<Organizations />} />
      {/* <Route key={"organization Config"} exact path="/administration/organizationconfig" element={<OrganizationConfig />} /> */}
      <Route key={"permissions"} exact path="/administration/permissions" element={<Permissions />} />
      <Route key={"roles"} exact path="/administration/roles" element={<Roles />} />
      <Route key={"users"} exact path="/administration/users" element={<Users />} />
      <Route key={"notfound"} exact path="*" element={<NotFound />} />
    </Routes>
  );
};

import * as React from 'react';
import { PageSection, PageSectionVariants, Text, TextContent } from "@patternfly/react-core";

export const PageHeadingComponent = ({ title, subtitle, icon }) => {
  return (
    <PageSection variant={PageSectionVariants.light} isWidthLimited>
      <TextContent>
        <Text component="h1">{ icon }&nbsp;{ title }</Text>
        <Text component="p">{ subtitle }</Text>
      </TextContent>
    </PageSection>
  );
};

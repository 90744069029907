import React, { createContext, useState, useContext, useEffect } from "react";
import { post, get } from "../../api/api";
import { localLog } from "../../utils/Utilities";

const AuthContext = createContext();
/*
  Organization id on the API calls to be changed 
*/
const AuthProvider = ({ children }) => {

  const [debug] = React.useState(false);

  const [authState, setAuthState] = useState(() => {
    const storedTokens = { idToken: localStorage.getItem("idToken") };
    storedTokens.accessToken = localStorage.getItem("accessToken");
    return storedTokens ? storedTokens : { idToken: null, accessToken: null };
  });

  useEffect(() => {
    const checkSession = async () => {
      try {
        // eslint-disable-next-line
        const response = await get(1, "/auth/check-session");
      } catch (error) {
        console.error(JSON.stringify(error));
      }
    };

    if (!authState?.idToken) {
      checkSession();
    }
        // eslint-disable-next-line
  }, []);

  const login = async (email_address) => {
    if (debug) localLog("auth.login");
    try {
      const response = await post(1, "/auth/login?email_address=" + email_address);
      if (response.data && response.data.accessToken) {
        const { idToken, accessToken } = response.data;
        setAuthState({ idToken, accessToken });
        localStorage.setItem("idToken", idToken);
        localStorage.setItem("accessToken", accessToken);
        window.location.href = "/";
      // } else if (response.config && response.config.headers && response.config.headers.Authorization) {
      //   if (debug) localLog("Authorization: " + response.config.headers.Authorization.slice(7));
      } else {
        if (debug) localLog("Other Error: " + JSON.stringify(response, null, 2));
        return response;
      }
    } catch (error) {
      console.error(JSON.stringify(error));
      // message: "Network Error", name: "AxiosError"
      if (error.config && error.config.headers && error.config.headers.Authorization) {
        if (debug) localLog("Authorization: " + error.config.headers.Authorization.slice(7));
        const idToken = error.config.headers.Authorization.slice(7);
        const accessToken = error.config.headers.Authorization.slice(7);
        setAuthState({ idToken, accessToken });
        localStorage.setItem("idToken", idToken);
        localStorage.setItem("accessToken", accessToken);
        window.location.href = "/";
      }
      return error;
    }
  };

  const processSSOCallback = (id, access) => {
    const redirectUri = encodeURIComponent(
      `${process.env.REACT_APP_SERVER_URL}/api/azure/callback`
    ); // Callback URL in your backend
    // window.location.href = `${process.env.REACT_APP_COG_DOMAIN}/oauth2/authorize?client_id=${process.env.REACT_APP_COGNITO_CLIENT_ID}&response_type=code&scope=email+openid+profile&redirect_uri=${redirectUri}`;
    window.location.assign(`${process.env.REACT_APP_COG_DOMAIN}/oauth2/authorize?client_id=${process.env.REACT_APP_COGNITO_CLIENT_ID}&response_type=code&scope=email+openid+profile&redirect_uri=${redirectUri}`);
    try {
      // localLog("processing sso");
      // Extract tokens from URL parameters
      const urlParams = new URLSearchParams(window.location.search);
      const idToken = urlParams.get("id_token");
      const accessToken = urlParams.get("access_token");

      if (!idToken || !accessToken) {
        throw new Error("Invalid tokens");
      }

      // Save tokens to localStorage and update state
      setAuthState({ idToken, accessToken });
      localStorage.setItem("idToken", idToken);
      localStorage.setItem("accessToken", accessToken);

      // Redirect to home or dashboard after successful login
      if(!authState || !authState.idToken) {
        window.location.href = "/";
      }
      
    } catch (error) {
      console.error(JSON.stringify(error));
      // Redirect to login page in case of error
      // history.push("/login");
    }
  };

  const logout = async () => {
    try {
      await post(1, "/auth/logout");
      setAuthState({ idToken: null, accessToken: null });
      localStorage.removeItem("idToken");
      localStorage.removeItem("accessToken");
      window.location.href = "/";
    } catch (error) {
      console.error(JSON.stringify(error));
    }
  };

  return (
    <AuthContext.Provider
      value={{ authState, setAuthState, login, processSSOCallback, logout }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthProvider };

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
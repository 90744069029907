import * as React from "react";
import { Breadcrumb, BreadcrumbItem, Bullseye, Button } from "@patternfly/react-core";
import { DataList } from "@patternfly/react-core";
import { Icon } from "@patternfly/react-core";
import { PageBreadcrumb, PageGroup, PageSection } from "@patternfly/react-core";
import { Spinner, Toolbar, ToolbarContent, ToolbarGroup, ToolbarItem, Tooltip } from "@patternfly/react-core";
import { SyncIcon, TagIcon } from "@patternfly/react-icons";

import { get } from "../../api/api";
import { OrganizationContext } from "../../common/contexts/OrganizationContext";
import { localLog } from "../../utils/Utilities";
import { PageHeadingComponent } from "../../components/PageHeadingComponent";
import { DepartmentModal } from "./DepartmentModal";
import { DepartmentDataListItem } from "./DepartmentDataListItem";

export const Departments = () => {

  const organization = React.useContext(OrganizationContext);

  const [debug] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [departments, setDepartments] = React.useState([]);
  const [department, setDepartment] = React.useState(null);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [isActionOpen, setIsActionOpen] = React.useState(0);

  const fetchDepartments = async () => {
    setLoading(true);
    try {
      const response = await get(organization.id, `/department/getDepartments?organizationId=${organization.id}`);
      if (debug) localLog("Departments: " + JSON.stringify(response.data));
      setDepartments(response.data);
      setLoading(false);
    } catch (error) {
      console.error(JSON.stringify(error));
      setLoading(false);
    }
  };

  React.useEffect(() => {

    const firstFetch = async () => {
      fetchDepartments();
    };

    if (organization?.id) {
      firstFetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organization.id]);

  const onRefreshDepartments = () => {
    fetchDepartments();
  };

  const onModalToggle = (e) => {
    setIsModalOpen(!isModalOpen);
  };

  const onClose = () => {
    onModalToggle();
  };

  // const onCreateDepartment = () => {
  //   setDepartment(null);
  //   onModalToggle();
  // };

  const onReadDepartment = (id) => {
    setDepartment(departments.find(item => item.id === id));
    onModalToggle();
  };

  const onEditDepartment = (id) => {
    setDepartment(departments.find(item => item.id === id));
    onModalToggle();
  };

  const onDeleteDepartment = (id) => {
    setDepartment(departments.find(item => item.id === id));
    onModalToggle();
  };

  const onActionSelect = () => {
    setIsActionOpen(0);
  };

  const onActionToggle = (id) => {
    if (isActionOpen === id)
      setIsActionOpen(0)
    else
      setIsActionOpen(id);
  };

  const onRefreshDepartment = (updatedDepartment) => {
    setDepartment(updatedDepartment);
    onModalToggle();
  };

  const renderToolbar = (
    <Toolbar>
      <ToolbarContent>
        <ToolbarGroup>
          <ToolbarItem variant="label">
            Departments
          </ToolbarItem>
        </ToolbarGroup>
        <ToolbarGroup align={{default: 'alignRight' }}>
          <ToolbarItem variant="separator" />
{/*
          <ToolbarItem>
            <Tooltip content="Create new Department">
              <Button icon={<PlusIcon />} variant="plain" onClick={onCreateDepartment}></Button>
            </Tooltip>
          </ToolbarItem>
*/}
          <ToolbarItem>
            <Tooltip content="Refresh List">
              <Button icon={<SyncIcon />} variant="plain" onClick={onRefreshDepartments}></Button>
            </Tooltip>
          </ToolbarItem>
        </ToolbarGroup>
      </ToolbarContent>
    </Toolbar>
  );

  if (loading) {
    return (
      <Bullseye height="100vh">
        <Spinner size="xl" />
      </Bullseye>
    );
  }

  return (
    <React.Fragment>
      <PageGroup stickyOnBreakpoint={{ default: 'top' }}>
        <PageBreadcrumb>
          <Breadcrumb>
            <BreadcrumbItem to="/">Dashboard</BreadcrumbItem>
            <BreadcrumbItem isActive>Administration</BreadcrumbItem>
            <BreadcrumbItem isActive>Departments</BreadcrumbItem>
          </Breadcrumb>
        </PageBreadcrumb>
        <PageHeadingComponent title="Departments" subtitle="Administer the departments used in the application." icon={<Icon><TagIcon /></Icon>} />
      </PageGroup>
      <PageSection>
        {
          renderToolbar
        }
        <DataList aria-label="Department List" isCompact>
          {
            departments.map((item) => (
              <DepartmentDataListItem key={item.id} department={item} isOpen={isActionOpen} setIsOpen={setIsActionOpen} onSelect={onActionSelect} onToggle={onActionToggle} onRead={onReadDepartment} onEdit={onEditDepartment} onDelete={onDeleteDepartment} />
            ))
          }
        </DataList>
      </PageSection>
      <DepartmentModal isOpen={isModalOpen} onClose={onClose} department={department} organizationId={organization.id} onRefresh={onRefreshDepartment} />
    </React.Fragment>
  );

};

import * as React from "react";
import { FormGroup, FormHelperText, HelperText, HelperTextItem, TextInput } from "@patternfly/react-core";

const ExternalUserData = ({ extUserName, extUserSurname, extUserOrganization, onExtUserNameChange, onExtUserSurnameChange, onExtUserOrganizationChange, nameErrorMessage, surnameErrorMessage, organizationErrorMessage, errorMessage, isRequired }) => {

  return (
    <>
      <FormGroup label="Name" isRequired fieldId="name">
        <TextInput id="name" placeholder="Name of the external sender" type="text" value={extUserName} isRequired validated={!nameErrorMessage} onChange={onExtUserNameChange}></TextInput>
        <FormHelperText><HelperText><HelperTextItem variant="error">{ nameErrorMessage }</HelperTextItem></HelperText></FormHelperText>
      </FormGroup>
      <FormGroup label="Surname" isRequired fieldId="surname">
        <TextInput id="surname" placeholder="Surname of external sender" type="text" value={extUserSurname} isRequired validated={!surnameErrorMessage} onChange={onExtUserSurnameChange} />
        <FormHelperText><HelperText><HelperTextItem variant="error">{ surnameErrorMessage }</HelperTextItem></HelperText></FormHelperText>
      </FormGroup>
      <FormGroup label="Organisation" isRequired fieldId="organisation">
        <TextInput id="organisation" placeholder="The organisation that the external sender belongs to" type="text" value={extUserOrganization} isRequired validated={!organizationErrorMessage} onChange={onExtUserOrganizationChange} />
        <FormHelperText><HelperText><HelperTextItem variant="error">{ organizationErrorMessage }</HelperTextItem></HelperText></FormHelperText>
      </FormGroup>
    </>
  );
};

export default ExternalUserData;

import * as React from "react";

import { Breadcrumb, BreadcrumbItem, Bullseye } from "@patternfly/react-core";
import { Form, FormGroup, Icon } from "@patternfly/react-core";
import { PageBreadcrumb, PageGroup, PageSection, PageSectionVariants, Panel, PanelMain, PanelMainBody } from "@patternfly/react-core";
import { Spinner, Stack, StackItem } from "@patternfly/react-core";
import { Text, TextContent, TextInput } from "@patternfly/react-core";
import { UserIcon } from "@patternfly/react-icons";

import { get } from "../api/api";
import { AvatarComponent } from "../components/AvatarComponent";
import { ManagerComponent } from "../components/ManagerComponent";

const UserProfile = ({ user, organization }) => {

  const [loading, setLoading] = React.useState(true);

  const [userData, setUserData] = React.useState(user);
  const [email, setEmail] = React.useState(user.email_address);
  // eslint-disable-next-line no-unused-vars
  const [roles, setRoles] = React.useState([]);
  const [department, setDepartment] = React.useState({});

  React.useEffect(() => {

    const fetchRoles = async () => {
      try {
        const response = await get(organization.id, `/role/getAllRolesByOrganization`);
        setRoles(response.data);
      } catch (error) {
        console.error(JSON.stringify(error));
      }
    };

    const fetchDepartment = async () => {
      try {
        const response = await get(organization.id, "/department/getDepartment/" + user.department_id);
        setDepartment(response.data);
      } catch (error) {
        console.error(JSON.stringify(error));
      }
    };

    const fetchUserData = async (user) => {
      try {
        const response = await get(organization.id, "/users/" + user.id);
        setUserData(response.data);
        setLoading(false);
      } catch (error) {
        console.error(JSON.stringify(error));
        setLoading(false);
      }
    };

    setUserData(user);
    fetchUserData(user);
    fetchDepartment();
    fetchRoles();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const handleEmailChange = (e, value) => {
    setEmail(value);
  };

  if (loading) {
    return (
      <Bullseye height="100vh">
        <Spinner size="xl" />
      </Bullseye>
    );
  }

  return (
    <React.Fragment>
      <PageGroup stickyOnBreakpoint={{ default: 'top' }}>
        <PageBreadcrumb>
          <Breadcrumb>
            <BreadcrumbItem to="/">Dashboard</BreadcrumbItem>
            <BreadcrumbItem isActive>User Profile</BreadcrumbItem>
          </Breadcrumb>
        </PageBreadcrumb>
        <PageSection variant={PageSectionVariants.light} isWidthLimited>
          <TextContent>
            <Text component="h1"><Icon><UserIcon /></Icon>&nbsp;User Profile</Text>
            <Text component="p">Details of the user.</Text>
          </TextContent>
        </PageSection>
      </PageGroup>
      <PageSection>
        <Stack hasGutter>
          <StackItem isFilled>
            <Panel>
              <PanelMain>
                <PanelMainBody>
                  <Form isHorizontal>
                    <FormGroup label="Name" isRequired fieldId="first_name">
                      <TextInput id="first_name" readOnlyVariant="plain" placeholder="Enter your name" type="text" value={userData.first_name} isRequired></TextInput>
                    </FormGroup>
                    <FormGroup label="Surname" isRequired fieldId="surname">
                      <TextInput id="surname" readOnlyVariant="plain" placeholder="Enter your surname" type="text" value={userData.surname} isRequired></TextInput>
                    </FormGroup>
                    <FormGroup label="Email" isRequired fieldId="email">
                      <TextInput id="email" readOnlyVariant="plain" placeholder="Enter your e-mail address" type="email" value={email} isRequired onChange={handleEmailChange}></TextInput>
                    </FormGroup>
                    <FormGroup label="Organisation Unit" isRequired fieldId="organisation">
                      <TextInput id="organisation" readOnlyVariant="plain" type="text" value={organization.name} isRequired></TextInput>
                      {
                        /*
                          <FormSelect id="organisation" onChange={handleOrganisationChange}>
                            <FormSelectOption key={organization.id} value={organization.id} label={organization.name} />
                          </FormSelect>
                        */
                      }
                    </FormGroup>
                    <FormGroup label="Role" isRequired fieldId="role">
                      <TextInput id="role" readOnlyVariant="plain" type="text" value={user.user_role} isRequired></TextInput>
                      {/* <FormSelect id="role" isDisabled value={userData?.role_id || ""}>
                        {
                          roles.map((role) => (
                            <FormSelectOption key={role.id} value={role.id} label={role.name} />
                          ))
                        }
                      </FormSelect> */}
                    </FormGroup>
                    <FormGroup label="Profile Picture" fieldId="picture">
                      <TextInput id="picture" readOnlyVariant="plain" type="text" value={user.profile_picture_url} isRequired></TextInput>
                      {/* <FileUpload id="picture" value={user.profile_picture_url} filename={user.profile_picture_url} isReadOnly="false"> */}
                      <AvatarComponent user={user} size="xl" />
                        {/* {
                          user.profile_picture_url && (
                            <div className={spacing.mMd}>
                              <img src={user.profile_picture_url} alt="Profile" style={{ height: "15em" }} />
                            </div>
                          )
                        } */}
                      {/* </FileUpload> */}
                    </FormGroup>
                    <FormGroup label="Manager" fieldId="manager">
                      <ManagerComponent user={user} />
                    </FormGroup>
                    <FormGroup label="Department" fieldId="department">
                      <TextInput id="department" readOnlyVariant="plain" type="text" value={department?.name} isRequired></TextInput>
                      {
                        /*
                          <FormSelect id="department">
                            <FormSelectOption key={department.id} value={department.id} label={department.name} />
                          </FormSelect>
                        */
                      }
                    </FormGroup>
                    {
                      /*
                        <ActionGroup>
                          <Button variant="primary">Submit</Button>
                          <Button variant="secondary">Cancel</Button>
                        </ActionGroup>
                      */
                    }
                  </Form>
                </PanelMainBody>
              </PanelMain>
            </Panel>
          </StackItem>
        </Stack>
      </PageSection>
    </React.Fragment>
  )
};

export { UserProfile };

import * as React from "react";
import { Breadcrumb, BreadcrumbItem, Bullseye, Button } from "@patternfly/react-core";
import { DataList } from "@patternfly/react-core";
import { Icon } from "@patternfly/react-core";
import { PageBreadcrumb, PageGroup, PageSection } from "@patternfly/react-core";
import { Spinner, Toolbar, ToolbarContent, ToolbarGroup, ToolbarItem, Tooltip } from "@patternfly/react-core";
import { FlagIcon, PlusIcon, SyncIcon } from "@patternfly/react-icons";

import { get } from "../../api/api";
import { OrganizationContext } from "../../common/contexts/OrganizationContext";
import { OrganizationToolbarToggleGroup } from "../../components/OrganizationToolbarToggleGroup";
import { PageHeadingComponent } from "../../components/PageHeadingComponent";
import { Role } from "./Role";
import { RoleDataListItem } from "./RoleDataListItem";
import { hasSuperAdminPermission } from "../../utils/Utilities";

export const Roles = () => {

  const ctx = React.useContext(OrganizationContext);

  const [organization, setOrganization] = React.useState(ctx);
  const [loading, setLoading] = React.useState(true);
  const [roles, setRoles] = React.useState([]);
  const [role, setRole] = React.useState(null);
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const [action, setAction] = React.useState("");
  const [isActionOpen, setIsActionOpen] = React.useState(0);

  const fetchRoles = async () => {
    setLoading(true);
    try {
      const response = await get(ctx.id, `/role/getAllRolesWithPermissions/${organization.id}`);
      setRoles(response.data);
      setLoading(false);
    } catch (error) {
      if (error.status === 404) {
        // No Records Found
        setRoles([]);
        setLoading(false);
      } else {
        console.error(JSON.stringify(error));
        setLoading(false);
      }
    }
  };

  React.useEffect(() => {

    const firstFetch = async () => {
      fetchRoles();
    };

    if (organization) {
      firstFetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organization]);

  const onRefreshRoles = () => {
    fetchRoles();
  };

  const onModalToggle = (e) => {
    setIsModalOpen(!isModalOpen);
  };

  const onAddRole = () => {
    setRole(undefined);
    setAction("create");
    onModalToggle();
  };

  const onClose = () => {
    onModalToggle();
  };

  const onDeleteRole = (id) => {
    setRole(roles.find(item => item.id === id));
    setAction("delete");
    onModalToggle();
  };

  const onEditRole = (id) => {
    setRole(roles.find(item => item.id === id));
    setAction("update");
    onModalToggle();
  };

  const onActionSelect = () => {
    setIsActionOpen(0);
  };

  const onActionToggle = (id) => {
    if (isActionOpen === id)
      setIsActionOpen(0)
    else
      setIsActionOpen(id);
  };

  const onUpdateRole = (updatedRole) => {
    onRefreshRoles();
    onModalToggle();
  };

  const renderToolbar = (
    <Toolbar>
      <ToolbarContent>
        <ToolbarGroup>
          <ToolbarItem variant="label">
            Roles
          </ToolbarItem>
        </ToolbarGroup>
        <ToolbarGroup align={{default: 'alignRight' }}>
          {
            hasSuperAdminPermission
            ? <OrganizationToolbarToggleGroup organization={organization} setOrganization={setOrganization} />
            : null
          }
          <ToolbarItem variant="separator" />
          <ToolbarItem>
            <Tooltip content="Create new Role">
              <Button icon={<PlusIcon />} variant="plain" onClick={onAddRole}></Button>
            </Tooltip>
          </ToolbarItem>
          <ToolbarItem>
            <Tooltip content="Refresh List">
              <Button icon={<SyncIcon />} variant="plain" onClick={onRefreshRoles}></Button>
            </Tooltip>
          </ToolbarItem>
        </ToolbarGroup>
      </ToolbarContent>
    </Toolbar>
  );

  if (loading) {
    return (
      <Bullseye height="100vh">
        <Spinner size="xl" />
      </Bullseye>
    );
  }

  return (
    <React.Fragment>
      <PageGroup stickyOnBreakpoint={{ default: 'top' }}>
        <PageBreadcrumb>
          <Breadcrumb>
            <BreadcrumbItem to="/">Dashboard</BreadcrumbItem>
            <BreadcrumbItem isActive>Administration</BreadcrumbItem>
            <BreadcrumbItem isActive>Roles</BreadcrumbItem>
          </Breadcrumb>
        </PageBreadcrumb>
        <PageHeadingComponent title="Roles" subtitle="Administer the roles used in the application." icon={<Icon><FlagIcon /></Icon>} />
      </PageGroup>
      <PageSection>
        {
          renderToolbar
        }
        <DataList aria-label="Role List" isCompact>
          {
            roles.map((item) => (
              <RoleDataListItem  key={item.id} role={item} isOpen={isActionOpen} setIsOpen={setIsActionOpen} onSelect={onActionSelect} onToggle={onActionToggle} onEdit={onEditRole} onDelete={onDeleteRole} />
            ))
          }
        </DataList>
      </PageSection>
      <Role isOpen={isModalOpen} onClose={onClose} role={role} organizationId={organization.id} action={action} onRefresh={onUpdateRole} />
    </React.Fragment>
  );

};

import * as React from "react";
import { Breadcrumb, BreadcrumbItem, Button, DataList, Icon, PageBreadcrumb, PageGroup, PageSection } from "@patternfly/react-core";
import { Toolbar, ToolbarContent, ToolbarGroup, ToolbarItem, Tooltip } from "@patternfly/react-core";
import { PackageIcon, PlusIcon, SyncIcon } from "@patternfly/react-icons";

import { get } from "../../api/api";
import { dynamicSort, localLog } from "../../utils/Utilities";
import { OrganizationContext } from "../../common/contexts/OrganizationContext";
import { ErrorStateComponent } from "../../components/ErrorStateComponent";
import { LoadingStateComponent } from "../../components/LoadingStateComponent";
import { NoResultsFoundComponent } from "../../components/NoResultsFoundComponent";
import { PageHeadingComponent } from "../../components/PageHeadingComponent";
import { CompetitionDataListItem } from "./CompetitionDataListItem";
import { CompetitionModal } from "./CompetitionModal";

export const Competitions = () => {

  const organization = React.useContext(OrganizationContext);

  const [action, setAction] = React.useState("create");
  const [debug] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [competition, setCompetition] = React.useState(null);
  const [competitions, setCompetitions] = React.useState([]);
  const [isActionOpen, setIsActionOpen] = React.useState(0);
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const fetchCompetitions = async () => {
    setError(null);
    setLoading(true);
    try {
      const response = await get(organization.id, `/scoringPeriod/getAllScoringPeriods`);
      if (debug) localLog("response: " + JSON.stringify(response, null, 2));
      if (response.data.scoringPeriods) {
        setCompetitions(response.data.scoringPeriods.sort(dynamicSort("date_from")));
      }
      setLoading(false);
    } catch (error) {
      console.error(JSON.stringify(error));
      if (error?.status === 404) {
        setCompetitions([]);
        setLoading(false);
      } else {
        setError(error);
        setLoading(false);
      }
    }
  };

  React.useEffect(() => {

    const firstFetch = async() => {
      fetchCompetitions();
    }

    if (organization?.id) {
      firstFetch();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organization.id]);

  const onActionSelect = () => {
    setIsActionOpen(0);
  };

  const onActionToggle = (id) => {
    if (isActionOpen === id) {
      setIsActionOpen(0);
    } else {
      setIsActionOpen(id);
    }
  };

  const onClose = () => {
    onModalToggle();
  };

  const onCreateCompetition = () => {
    setCompetition(undefined);
    setAction("create");
    onModalToggle();
  };

  const onDeleteCompetition = (id) => {
    setCompetition(competitions.find(item => item.id === id));
    setAction("delete");
    onModalToggle();
  };

  const onUpdateCompetition = (id) => {
    const comp = competitions.find((item) => item.id === id);
    setCompetition(comp);
    setAction("update");
    onModalToggle();
  };

  const onModalToggle = (e) => {
    setIsModalOpen(!isModalOpen);
  };

  const onRefresh = () => {
    setCompetition(null);
    if (isModalOpen)
      setIsModalOpen(false);
    fetchCompetitions();
  };

  const CompetitionsToolbar = (
    <Toolbar>
      <ToolbarContent>
        <ToolbarGroup>
          <ToolbarItem variant="label">
            Competitions
          </ToolbarItem>
        </ToolbarGroup>
        <ToolbarGroup align={{default: 'alignRight' }}>
          <ToolbarItem variant="separator" />
          <ToolbarItem>
            <Tooltip content="Create New Competition">
              <Button variant="plain" onClick={onCreateCompetition}><Icon><PlusIcon /></Icon></Button>
            </Tooltip>
          </ToolbarItem>
          <ToolbarItem>
            <Tooltip content="Refresh List">
              <Button variant="plain" onClick={onRefresh}><Icon><SyncIcon /></Icon></Button>
            </Tooltip>
          </ToolbarItem>
        </ToolbarGroup>
      </ToolbarContent>
    </Toolbar>
  );

  return (
    <React.Fragment>
      <PageGroup stickyOnBreakpoint={{ default: 'top' }}>
        <PageBreadcrumb>
          <Breadcrumb>
            <BreadcrumbItem to="/">Dashboard</BreadcrumbItem>
            <BreadcrumbItem isActive>Administration</BreadcrumbItem>
            <BreadcrumbItem isActive>Competitions</BreadcrumbItem>
          </Breadcrumb>
        </PageBreadcrumb>
        <PageHeadingComponent title="Competitions" subtitle="Administer the competitions used in the application." icon={<PackageIcon />} />
      </PageGroup>
      <PageSection>
        {
          loading
          ? <LoadingStateComponent />
          : error
            ? <ErrorStateComponent error={error} />
            : <>
                {
                  CompetitionsToolbar
                }
                {
                  competitions.length === 0
                  ? <NoResultsFoundComponent isFullHeight={false} title="No Competitions Found" message="There are currently no competitions defined for the application." />
                  : <DataList isCompact>
                      {
                        competitions.map((item) => (
                          <CompetitionDataListItem
                            key={item.id}
                            competition={item}
                            isOpen={isActionOpen}
                            setIsOpen={setIsActionOpen}
                            onSelect={onActionSelect}
                            onToggle={onActionToggle}
                            onUpdate={onUpdateCompetition}
                            onDelete={onDeleteCompetition}
                          />
                        ))
                      }
                    </DataList>
                }
              </>
        }
      </PageSection>
      <CompetitionModal
        isOpen={isModalOpen}
        onClose={onClose}
        competition={competition}
        organizationId={organization.id}
        action={action}
        onRefresh={onRefresh}
      />
    </React.Fragment>
  );

};

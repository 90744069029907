import * as React from "react";
import { Gallery } from "@patternfly/react-core";

import { ShoutOutCard } from "./ShoutOutCard";

export const ShoutOutCards = ({ user, organization, shoutouts, setShoutouts }) => {

  return (
    <Gallery hasGutter minWidths={{ default: '100%' }}>
    {
      shoutouts.map((shoutout) => (
        <ShoutOutCard key={shoutout.id} user={user} organization={organization} shoutout={shoutout} setShoutouts={setShoutouts} />
      ))
    }
  </Gallery>
);

};
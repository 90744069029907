import * as React from "react";
import { Bullseye, Button, Card, CardBody, CardTitle } from "@patternfly/react-core";
import { Split, SplitItem, Stack, StackItem } from "@patternfly/react-core";
import { Text, TextContent } from "@patternfly/react-core";
import { EditIcon, TimesIcon } from "@patternfly/react-icons";

import { format, formatDistanceToNow } from "date-fns";

import { UserContext } from "../../common/contexts/UserContext";
import { AvatarComponent } from "../../components/AvatarComponent";
import { ConfirmationModal } from "../../modals/ConfirmationModal";
import { ShoutOutCommentModal } from "../../modals/ShoutOutCommentModal";

export const ShoutOutCommentComponent = ({ comment, onEdit, onDelete }) => {

  const user = React.useContext(UserContext);

  const [isConfirmationOpen, setIsConfirmationOpen] = React.useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = React.useState(false);
  const [newComment, setNewComment] = React.useState(comment.comment);

  const onConfirmationClose = () => {
    setIsConfirmationOpen(false);
  };

  const onDeleteComment = () => {
    setIsConfirmationOpen(false);
    onDelete(comment);
  };

  const onEditModalClose = () => {
    setIsEditModalOpen(false);
  };

  const onEditComment = () => {
    setIsEditModalOpen(false);
    onEdit(comment, newComment);
  };

  React.useEffect(() => {
    //
  }, [user]);

  return (
    <>
      <Card isCompact isFlat>
        <CardTitle>
          <Split hasGutter>
            <SplitItem>
              <Bullseye>
                <AvatarComponent user={comment?.user} size="md" />
              </Bullseye>
            </SplitItem>
            <SplitItem isFilled>
              <TextContent>
                <Text component="h5" style={{ marginBottom: '0px' }}>{`${comment.user.first_name} ${comment.user.surname}`}</Text>
                <Text component="h5" style={{ marginTop: '0px' }}>{comment.user.user_role}</Text>
              </TextContent>
            </SplitItem>
            <SplitItem>
              <Stack>
                <StackItem>
                  <TextContent>
                    <Text style={{ float: 'right' }}>
                      {
                        (user.id === comment.user_id) && (comment.date.slice(0, 10) === (format(new Date(), 'yyyy-MM-dd')))
                        ? <Button isInline size="sm" variant="secondary" icon={<EditIcon />} onClick={() => setIsEditModalOpen(true)}>Edit</Button>
                        : null
                      }
                      {' '}
                      {
                        (user.id === comment.user_id) && (comment.date.slice(0, 10) === (format(new Date(), 'yyyy-MM-dd')))
                        ? <Button isInline size="sm" variant="secondary" isDanger icon={<TimesIcon />} onClick={() => setIsConfirmationOpen(true)}>Delete</Button>
                        : null
                      }
                    </Text>
                  </TextContent>
                </StackItem>
                <StackItem>
                  <TextContent>
                    <Text component="i" style={{ float: 'right' }}>
                      {
                        formatDistanceToNow(new Date(comment.date), { addSuffix: true })
                      }
                    </Text>
                  </TextContent>
                </StackItem>
              </Stack>
            </SplitItem>
          </Split>
        </CardTitle>
        <CardBody>
          <TextContent>
            <Text>{comment.comment}</Text>
          </TextContent>
        </CardBody>
      </Card>
      <ShoutOutCommentModal title={`Edit Comment`} message={`You can edit the comment here.`} comment={newComment} setComment={setNewComment} isOpen={isEditModalOpen} onClose={onEditModalClose} onAction={onEditComment} />
      <ConfirmationModal title={`Permanently Delete Record?`} message={`The comment will be lost.`} isDelete isOpen={isConfirmationOpen} onClose={onConfirmationClose} onAction={onDeleteComment} />
    </>
  );

};
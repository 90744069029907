import * as React from "react";
import { TextInput } from "@patternfly/react-core";
import { get } from "../api/api";
import { OrganizationContext } from "../common/contexts/OrganizationContext";

export const ManagerComponent = ({ user }) => {

  const organization = React.useContext(OrganizationContext);
  const [manager, setManager] = React.useState("");

  React.useEffect(() => {

    const fetchManager = async() => {
      setManager("");
      if (!user)
        return;
      if (user.manager_id === null)
        return;
      try {
        const response = await get(organization.id, "/users/" + user.manager_id);
        setManager(response.data.first_name + " " + response.data.surname);
      } catch (error) {
        console.error(JSON.stringify(error));
      }
    };

    fetchManager();

  }, [user, organization.id]);

  return (
    <TextInput id="manager" readOnlyVariant="plain" type="text" value={manager} isRequired />
  );

}
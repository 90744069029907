import * as React from "react";
import { EmptyState, EmptyStateHeader, EmptyStateIcon, Spinner } from "@patternfly/react-core";

export const LoadingStateComponent = () => {

  return (
    <EmptyState isFullHeight>
      <EmptyStateHeader titleText="Loading" headingLevel="h4" icon={<EmptyStateIcon icon={Spinner} />} />
    </EmptyState>
  );

};
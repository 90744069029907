import * as React from "react";
import { useNavigate } from "react-router-dom";
import { Bullseye } from "@patternfly/react-core";
import { Card, CardBody } from "@patternfly/react-core";
import { Flex, FlexItem } from "@patternfly/react-core";
import { GalleryItem } from "@patternfly/react-core";
import { NotificationBadge, NotificationBadgeVariant } from "@patternfly/react-core";
import { Panel, PanelMain, PanelMainBody } from "@patternfly/react-core";
import { Split, SplitItem } from "@patternfly/react-core";
import { Stack, StackItem } from "@patternfly/react-core";
import { Text, TextContent, Tooltip } from "@patternfly/react-core";

import { CommentsIcon, ThumbsUpIcon } from "@patternfly/react-icons";

import { formatDistanceToNow } from "date-fns";

import { get, post } from "../../api/api";
// import { NotificationContext } from "../../common/contexts/NotificationContext";
import { AvatarComponent } from "../../components/AvatarComponent";
import { ShoutOutModal } from "./ShoutOutModal";

export const ShoutOutCard = ({ user, organization, shoutout, setShoutouts }) => { 

  const navigate = useNavigate();

//  const { addNotification } = React.useContext(NotificationContext);

  const [liked, setLiked] = React.useState(false);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  // eslint-disable-next-line no-unused-vars
  const [numLikes, setNumLikes] = React.useState(shoutout.shoutout_likes.length);

  const onToggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const onClose = () => {
    onToggleModal();
  };

  const onOpen = () => {
    navigate(`/shoutouts/${shoutout.id}`);
//    setIsModalOpen(true);
  };

  const handleLike = async () => {
    try {
      const newLikedState = !liked;
      // Update likes count based on the new like state
      setNumLikes(prevNumLikes => newLikedState ? prevNumLikes + 1 : Math.max(prevNumLikes - 1, 0));
      setLiked(newLikedState);
      const likeBody = {
        "user_id": user?.id,
        "shoutout_id": shoutout.id,
        "points": newLikedState ? 10 : 0,
      };
      await post(organization.id, "/shoutoutInteraction/addLike", likeBody);
      // This response should be the same as the /shoutout/view/ response, but it is not
      // addNotification({ variant: 'success', title: 'Success', description: `You ${newLikedState ? 'liked' : 'unliked'} a ShoutOut.` });
      const response = await get(organization.id, "/shoutout/view/" + shoutout.id );
      setShoutouts((prevShoutouts) =>
        prevShoutouts.map((shoutout) =>
          shoutout.id === response.data.id ? response.data : shoutout
        )
      );
    } catch (error) {
      // addNotification({ variant: 'danger', title: 'Error Liking a ShoutOut', description: `${JSON.stringify(error?.message)}`});
      console.error(JSON.stringify(error));
    }
  };

  React.useEffect(() => {
    // Check if the user has already liked the shoutout
    const hasLiked = shoutout.shoutout_likes.some(like => like.user_id === user?.id);
    setLiked(hasLiked);
  }, [shoutout, user]);

  return (
    <>
    <GalleryItem>
    <Card isCompact>
      <CardBody>
        <Split hasGutter>
          <SplitItem style={{ minWidth: '72px'}}>
            <AvatarComponent user={shoutout?.toUser} size="lg" />
          </SplitItem>
          <SplitItem isFilled>
            <Stack>
              <StackItem>
                <Flex>
                  <FlexItem><TextContent><Text component="b">ShoutOut to</Text></TextContent></FlexItem>
                  <FlexItem><TextContent><Text component="h2">{`${shoutout.toUser.first_name} ${shoutout.toUser.surname}`}</Text></TextContent></FlexItem>
                  <FlexItem align={{ default: 'alignRight' }}>{ formatDistanceToNow(new Date(shoutout.submitted_date), { addSuffix: true }) }</FlexItem>
                  <FlexItem>
                    <Tooltip content="Comments">
                      <NotificationBadge icon={<CommentsIcon />} count={shoutout.shoutout_comments.length} variant={NotificationBadgeVariant.unread} onClick={onOpen} />
                    </Tooltip>
                  </FlexItem>
                  <FlexItem>
                    <Tooltip content={liked ? "Unlike" : "Like"}>
                      <NotificationBadge icon={<ThumbsUpIcon />} count={shoutout.shoutout_likes.length} variant={liked ? NotificationBadgeVariant.unread : NotificationBadgeVariant.read} onClick={handleLike} />
                    </Tooltip>
                  </FlexItem>
                </Flex>
              </StackItem>
              <StackItem>
                <Flex flex={{ default: 'flex_1' }}>
                  <FlexItem alignSelf={{ default: 'alignSelfFlexStart'}}><Bullseye><TextContent><Text component="i">for</Text></TextContent></Bullseye></FlexItem>
                  { shoutout.category1 && ( <FlexItem><Bullseye><img src={shoutout.category1?.icon_url.startsWith("https://") ? shoutout.category1?.icon_url : `https://shoutout-file-uploads.s3.af-south-1.amazonaws.com/${shoutout.category1?.icon_url}`} alt={shoutout.category1.name} width="24px" height="24px" /> &nbsp; { shoutout.category1.name }</Bullseye></FlexItem> ) }
                  { shoutout.category2 && ( <FlexItem><Bullseye><img src={shoutout.category2?.icon_url.startsWith("https://") ? shoutout.category2?.icon_url : `https://shoutout-file-uploads.s3.af-south-1.amazonaws.com/${shoutout.category2?.icon_url}`} alt={shoutout.category2.name} width="24px" height="24px" /> &nbsp; { shoutout.category2.name }</Bullseye></FlexItem> ) }
                  { shoutout.category3 && ( <FlexItem><Bullseye><img src={shoutout.category3?.icon_url.startsWith("https://") ? shoutout.category3?.icon_url : `https://shoutout-file-uploads.s3.af-south-1.amazonaws.com/${shoutout.category3?.icon_url}`} alt={shoutout.category3.name} width="24px" height="24px" /> &nbsp; { shoutout.category3.name }</Bullseye></FlexItem> ) }
                  { shoutout.category4 && ( <FlexItem><Bullseye><img src={shoutout.category4?.icon_url.startsWith("https://") ? shoutout.category4?.icon_url : `https://shoutout-file-uploads.s3.af-south-1.amazonaws.com/${shoutout.category4?.icon_url}`} alt={shoutout.category4.name} width="24px" height="24px" /> &nbsp; { shoutout.category4.name }</Bullseye></FlexItem> ) }
                </Flex>
              </StackItem>
              <StackItem>
                <Flex>
                  <FlexItem><TextContent><Text component="i">from</Text></TextContent></FlexItem>
                  <FlexItem>
                    <TextContent>
                      <Text component="h4">
                        {
                          shoutout.external_shoutout_YN
                          ? shoutout.external_user_full_name
                          : `${shoutout.fromUser.first_name} ${shoutout.fromUser.surname}`
                        }
                      </Text>
                    </TextContent>
                  </FlexItem>
                </Flex>
              </StackItem>
              <StackItem>
                    <Panel isScrollable variant="bordered">
                      <PanelMain tabIndex={0} style={{ maxHeight: '80px' }}>
                        <PanelMainBody>
                          <TextContent>
                            <Text component="i">
                              <div style={{ wordBreak: 'break-word'}}> 
                                { shoutout.comment }
                              </div>
                            </Text>
                          </TextContent>
                        </PanelMainBody>
                      </PanelMain>
                    </Panel>
              </StackItem>
            </Stack>
          </SplitItem>
        </Split>
      </CardBody>
    </Card>
  </GalleryItem>
  <ShoutOutModal shoutout={shoutout} setShoutouts={setShoutouts} isOpen={isModalOpen} onClose={onClose} />
  </>
);

};
import * as React from "react";
import { Card, CardBody, CardHeader, CardTitle, Icon, Modal, ModalVariant, Split, SplitItem, Text, TextContent, TextVariants, Title } from "@patternfly/react-core";
import { BullhornIcon, CommentsIcon, OutlinedCalendarAltIcon, FlagCheckeredIcon, ThumbsUpIcon, PollIcon } from "@patternfly/react-icons";

import { get } from "../api/api";
import { OrganizationContext } from "../common/contexts/OrganizationContext";
import { localLog } from "../utils/Utilities";
import { AvatarComponent } from "../components/AvatarComponent";

export const UserStatisticsModal = ({ isOpen, onClose, userId, competition, startDate, endDate }) => {

  const organization = React.useContext(OrganizationContext);

  const [debug] = React.useState(false);
  const [userStats, setUserStats] = React.useState(null);

  const fetchData = async () => {
    try {
      const response = await get(organization.id, "/shoutout/getUserStats?user_id=" + userId + "&organizationId=" + organization.id + "&userId=" + userId + "&startDate=" + startDate + "&endDate=" + endDate);
      if (debug) localLog("response: " + JSON.stringify(response.data));
      setUserStats(response.data[0]);
    } catch (error) {
      console.error(JSON.stringify(error));
    }
  };

  React.useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const UserAvatar = () => {
    return (
      <AvatarComponent user={userStats} size="md" />
    )
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={`${userStats?.firstname} ${userStats?.surname}`}
      titleIconVariant={UserAvatar}
      variant={ModalVariant.small}
    >
      {
        userStats && (
          <>
            <Card>
              <CardHeader>
                <CardTitle>
                  <Title headingLevel="h4" size="xl">
                    Competition: {competition?.period_name}
                  </Title>
                  <Title headingLevel="h4" size="lg">
                    <Icon><OutlinedCalendarAltIcon /></Icon> {competition?.date_from.slice(0, 10)} - <Icon><OutlinedCalendarAltIcon /></Icon> {competition?.date_to.slice(0, 10)}
                  </Title>
                </CardTitle>
              </CardHeader>
              <CardBody>
                <InformationCard icon={<FlagCheckeredIcon />} text="Rank" value={userStats.user_rank} />
                <InformationCard icon={<PollIcon />} text="Total Points" value={userStats.total_points} />
                <InformationCard icon={<BullhornIcon />} text="ShoutOuts Received" value={userStats.total_shoutouts_received} />
                <InformationCard icon={<ThumbsUpIcon />} text="Likes Received" value={userStats.total_likes_received} />
                <InformationCard icon={<CommentsIcon />} text="Comments Received" value={userStats.total_comments_received} />
              </CardBody>
            </Card>
          </>
        )
      }
    </Modal>
  );
};

const InformationCard = ({ icon, text, value }) => {

  return (
    <Card>
    <CardBody>
      <Split hasGutter>
        <SplitItem>
          <Icon size="xl">{ icon }</Icon>
        </SplitItem>
        <SplitItem isFilled>
          <TextContent style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Text component={TextVariants.h3}>
              <b>{ text }</b>
            </Text>
          </TextContent>
          <TextContent style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Text component={TextVariants.h2}>
              {value}
            </Text>
          </TextContent>
        </SplitItem>
      </Split>
    </CardBody>
  </Card>

  );
};
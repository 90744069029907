import * as React from "react";
import { Bullseye, Card, CardBody, Flex, FlexItem, GalleryItem, Text, TextContent } from "@patternfly/react-core";
import { UserStatisticsModal } from "../modals/UserStatisticsModal";
import { AvatarComponent } from "./AvatarComponent";

export const LeaderboardCardComponent = ({ index, user, competition, startDate, endDate }) => {

  const [isOpen, setIsOpen] = React.useState(false);

  const onModalToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <GalleryItem>
      <Card onClick={onModalToggle}>
        <CardBody>
          <Flex spaceItems={{ default: 'spaceItemsLg'}}>
            <Flex flex={{ default: 'flex_1' }} alignItems={{ default: 'alignItemsCenter' }}>
              <FlexItem>
                <Bullseye>
                  <TextContent><Text>{index + 1}</Text></TextContent>
                </Bullseye>
              </FlexItem>
              <FlexItem>
                <AvatarComponent user={user} size="sm" />
              </FlexItem>
              <FlexItem grow={{ default: 'grow'}}>
                <TextContent>
                  <Text>{`${user.firstname} ${user.surname}`}</Text>
                </TextContent>
              </FlexItem>
              <FlexItem>
                <TextContent>
                  <Text>
                    {user.total_points} Points
                  </Text>
                </TextContent>
              </FlexItem>
            </Flex>
          </Flex>
        </CardBody>
      </Card>
      <UserStatisticsModal isOpen={isOpen} onClose={onModalToggle} userId={user.user_id} competition={competition} startDate={startDate} endDate={endDate} />
    </GalleryItem>
  );
};

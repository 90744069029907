import * as React from "react";
import { Form, FormGroup, Modal, ModalVariant, TextInput } from "@patternfly/react-core";
import { TagIcon } from "@patternfly/react-icons";

export const DepartmentModal = ({ isOpen, onClose, department, organizationId, onRefresh }) => {

  const [formData, setFormData] = React.useState(null);

  React.useEffect(() => {
    setFormData(department);
  }, [department]);

  return (
    <Modal
      aria-label="Role Modal"
      variant={ModalVariant.medium}
      title={<><TagIcon /> View Department</>} 
      isOpen={isOpen} 
      onClose={onClose}
    >
      <Form id="role-form" isHorizontal>
        <FormGroup label="Name" isRequired fieldId="name">
          <TextInput id="name" readOnlyVariant="plain" placeholder="Enter the name of the role" type="text" value={formData?.name} isRequired onChange={(e, value) => setFormData({ ...formData, name: value })}></TextInput>
        </FormGroup>
      </Form>
    </Modal>
  );
};

import * as React from "react";
import { Text, TextContent, TextVariants } from "@patternfly/react-core";
import { EnterpriseIcon } from "@patternfly/react-icons";
import { get_clean } from "../api/api";
import { OrganizationContext } from "../common/contexts/OrganizationContext";

export const OrganizationTextComponent = ({ user, showIcon }) => {

  const ctx = React.useContext(OrganizationContext);
  const [organization, setOrganization] = React.useState("");

  React.useEffect(() => {

    const fetchOrganization = async() => {
      setOrganization("");
      if (!user)
        return;
      if (user.organization_id === null)
        return;
      try {
        const response = await get_clean("/organizations/" + user.organization_id);
        setOrganization(response.data.name);
      } catch (error) {
        console.error(JSON.stringify(error));
      }
    };

    fetchOrganization();

  }, [user, ctx.id]);

  return (
    <TextContent><Text component={TextVariants.p}>{ showIcon && organization.length > 0 ? (<EnterpriseIcon />) : ''}{ " " + organization }</Text></TextContent>
  );

}
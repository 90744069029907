import * as React from "react";
import { Alert, Button, Form, FormAlert, FormGroup, FormHelperText, HelperText, HelperTextItem, Modal, ModalVariant, TextInput } from "@patternfly/react-core";

import { post_clean, put_clean } from "../../api/api";
import { NotificationContext } from "../../common/contexts/NotificationContext";
import { localLog } from "../../utils/Utilities";

export const OrganizationModal = ({ isOpen, onClose, organization = null, organizationId, action, onRefresh }) => {

  const { addNotification } = React.useContext(NotificationContext);

  const [debug] = React.useState(false);
  const [formData, setFormData] = React.useState(null);
  const [formErrors, setFormErrors] = React.useState({});

  React.useEffect(() => {
    setFormData(organization);
    setFormErrors({});
  }, [organization]);

  const onSave = async () => {
    const errors = {};
    if (formData?.name === "")
      errors.name = "Name is required";
    setFormErrors(errors);
    if (Object.keys(errors).length > 0) {
      return;
    }
    try {
      const data = {
        name: formData.name,
      };
      if (organization) {
        // Update organization
        const response = await put_clean(`/organizations/updateOrganization/${organization.id}`, data);
        addNotification({ variant: 'success', title: 'Organization Updated', description: 'The organization has been updated successfully.' });
        onRefresh(response.data);
      } else {
        // Create new organization
        if (debug) localLog("Create Organization: " + JSON.stringify(data));
        const response = await post_clean(`/organizations/createOrganization/`, data);
        addNotification({ variant: 'success', title: 'Organization Created', description: 'The organization has been created successfully.' });
        onRefresh(response.data);
      }
      onClose();
    } catch (error) {
      console.error(JSON.stringify(error));
      addNotification({ variant: 'error', title: 'Organization Error', description: 'An error occurred while trying to update the organization.' });
    }
  };

  return (
    <Modal 
      aria-label="Organization Modal"
      variant={ModalVariant.medium}
      title={action === "delete" ? "Delete Organization" : organization ? "Edit Organization" : "Add Organization"} 
      isOpen={isOpen} 
      onClose={onClose}
      actions={
        action === "delete"
        ? [
            <Button key="delete" variant="danger" form="organization-form">Delete</Button>,
            <Button key="cancel" variant="secondary" onClick={onClose}>Cancel</Button>
          ]
        : [
          <Button key="save" variant="primary" form="organization-form" onClick={onSave}>Save</Button>,
          <Button key="cancel" variant="secondary" onClick={onClose}>Cancel</Button>
        ]
      }
    >
      <Form id="organization-form" isHorizontal>
        {
          formErrors && Object.keys(formErrors).length > 0 && (
            <FormAlert>
              <Alert variant="danger" title="Fill out all required fields before continuing." isInline />
            </FormAlert>
          )
        }
        <FormGroup label="Name" isRequired fieldId="name">
          <TextInput
            id="name"
            isRequired
            placeholder="Enter the name of the organization"
            type="text"
            validated={formErrors?.name ? "error" : "default"}
            value={formData?.name || ""}
            onChange={(e, value) => setFormData({ ...formData, name: value })}
          />
          <FormHelperText>
            <HelperText>
              <HelperTextItem variant={formErrors?.name ? "error" : "default"}>
                { formErrors?.name }
              </HelperTextItem>
            </HelperText>
          </FormHelperText>
        </FormGroup>
      </Form>
    </Modal>
  );
};

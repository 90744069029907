import * as React from "react";
import { FileUpload } from "@patternfly/react-core";
import { localLog } from "../utils/Utilities";

const SingleFileUploadComponent = ({ id, accept, filename, setFilename, value, setValue }) => {

  const [isLoading, setIsLoading] = React.useState(false);
  const [isRejected, setIsRejected] = React.useState(false);

  const onFileInputChange = (_event, file) => {
    localLog("SingleFileUploadComponent.onFileInputChange file: " + JSON.stringify(file, null, 2));
    setValue(file);
    setFilename(file.name);
  };

  const onTextChange = (_event, val) => {
    localLog("SingleFileUploadComponent.onTextChange value: " + val);
    if (setValue) 
      setValue(val);
  };

  const onDataChange = (_event, val) => {
    localLog("SingleFileUploadComponent.onDataChange value: " + val);
    // if (setValue)
    //   setValue(val);
  };

  const onClearClick = (_event) => {
    setFilename("");
    if (setValue)
      setValue(undefined);
    setIsRejected(false);
  };

  const onFileRejected = (fileRejections, _event) => {
    localLog("SingleFileUploadComponent.onFileRejected fileRejections: " + JSON.stringify(fileRejections, null, 2));
    setIsRejected(true);
  };

  const onFileReadStarted = (_event, _fileHandle) => {
    setIsLoading(true);
  };

  const onFileReadFinished = (_event, _fileHandle) => {
    setIsLoading(false);
  };

  return (
    <FileUpload
      id={id}
      type="dataURL"
      value={value}
      filename={filename}
      filenamePlaceholder="Drag and drop a file or upload one"
      onFileInputChange={onFileInputChange}
      onDataChange={onDataChange}
      onTextChange={onTextChange}
      onReadStarted={onFileReadStarted}
      onReadFinished={onFileReadFinished}
      onClearClick={onClearClick}
      isLoading={isLoading}
      hideDefaultPreview={true}
      dropzoneProps={{
        accept: accept,
        onDropReject: onFileRejected
      }}
      validated={isRejected ? 'error' : 'default'}
      browseButtonText="Upload"
    />
  );

};

export { SingleFileUploadComponent };
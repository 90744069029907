import * as React from "react";
import { AuthProvider } from "./common/contexts/AuthContext";
import { AppContent } from "./AppContent";

import "@patternfly/react-core/dist/styles/base.css";
import "./App.css";

export default function App() {
  return (
    <AuthProvider>
      <AppContent />
    </AuthProvider>
  );
}

import * as React from "react";
import { Alert, Button, Form, FormAlert, FormGroup, FormHelperText, HelperText, HelperTextItem, Modal, ModalVariant, TextInput } from "@patternfly/react-core";

import { del, post, put } from "../../api/api";
import { NotificationContext } from "../../common/contexts/NotificationContext";
import { localLog } from "../../utils/Utilities";
import { SingleFileUploadComponent } from "../../components/SingleFileUploadComponent";

export const CategoryModal = ({ isOpen, onClose, category, organizationId, action, onRefresh}) => {

  const { addNotification } = React.useContext(NotificationContext);

  const [debug] = React.useState(false);
  const [formData, setFormData] = React.useState(category);
  const [pictureFiledata, setPictureFiledata] = React.useState(null);
  const [formErrors, setFormErrors] = React.useState({});

  React.useEffect(() => {
    if (debug) localLog("category: " + JSON.stringify(category));
    setFormData(category);
    setFormErrors({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category]);

  // eslint-disable-next-line no-unused-vars
  const onInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // eslint-disable-next-line no-unused-vars
  const onFileChange = (e) => {
    setFormData({ ...formData, icon: e.target.files[0] });
  };

  const validateForm = () => {
    const errors = {};
    if (!formData.name) {
      errors.name = "Category Name is required";
    }
    if (!formData.points) {
      errors.points = "Points are required";
    }
    if (isNaN(Number(formData.points))) {
      errors.points = "Points must be a number";
    }
    if (Number(formData.points) < 0) {
      errors.points = "Points must be a positive number";
    }
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const onDelete = async(e) => {
    try {
      const response = await del(organizationId, `/shoutoutcategory/delete/${category.id}`);
      if (debug) localLog("Response after Delete: " + JSON.stringify(response.data, null, 2));
      addNotification({ variant: 'success', title: 'Category Deleted', description: 'The category has been deleted successfully.' });
      onRefresh(response.data);
    } catch (error) {
      console.error(JSON.stringify(error));
      addNotification({ variant: 'error', title: 'Error Deleting Category', description: 'An error occurred while trying to delete the category.' });
    }
  };

  // Upload files
  const uploadAttachment = async (file) => {
    try {
      const response = await post(organizationId, `/attachments/upload/single`, { file: file }, { headers: { "Content-Type": "multipart/form-data" }});
      return response.data.fileLocation;
    } catch (error) {
      if (error.response) {
        console.error(JSON.stringify(error.response));
      } else 
        console.error(JSON.stringify(error));
    }
  };

  const onSave = async (e) => {
    // e.preventDefault();
    if (validateForm()) {
      try {
        const form = {
          organizationId: organizationId,
          name: formData.name,
          points: Number(formData.points),
          icon_url: await uploadAttachment(pictureFiledata),
          description: formData.description
        }
        if (category) {
          // Update Category
          try {
            if (debug) localLog("formData: " + JSON.stringify(formData, null, 2));
            const response = await put(organizationId, `/shoutoutcategory/update/${category.id}`, formData);
            if (debug) localLog("Response after Update: " + JSON.stringify(response.data, null, 2));
            addNotification({ variant: 'success', title: 'Category Updated', description: 'The category has been updated successfully.' });
            onRefresh(response.data);
          } catch (error) {
            console.error(JSON.stringify(error));
            addNotification({ variant: 'error', title: 'Error Updating Category', description: 'An error occurred while trying to update the category.' });
          }
        } else {
          // Create Category
          try {
            const response = await post(organizationId, `/shoutoutcategory/create`, form, { headers: { "Content-Type": "multipart/form-data" } });
            if (debug) localLog("Response after Create: " + JSON.stringify(response.data, null, 2));
            addNotification({ variant: 'success', title: 'Category Created', description: 'The category has been created successfully.'});
            onRefresh(response.data);
          } catch (error) {
            console.error(JSON.stringify(error));
            addNotification({ variant: 'error', title: 'Error Creating Category', description: 'An error occurred while trying to create the category.' });
          }
        }
        onClose();
      } catch (error) {
        console.error(JSON.stringify(error));
        addNotification({ variant: 'error', title: 'Error Occurred', description: 'An error occurred while trying to update the category.' });
      }
    }
  };

  return (
    <Modal
      aria-label="Category Modal"
      variant={ModalVariant.medium}
      title={category ? "Edit Category" : "Add Category"}
      isOpen={isOpen}
      onClose={onClose}
      actions={[
        <Button key="save" variant={action === "delete" ? "danger" : "primary"} form="category-form" onClick={action === "delete" ? onDelete : onSave}>{action === "delete" ? "Delete" : "Save"}</Button>,
        <Button key="cancel" variant="secondary" onClick={onClose}>Cancel</Button>
      ]}
    >
      <Form id="category-form" isHorizontal>
        {
          formErrors && Object.keys(formErrors).length > 0 && (
            <FormAlert>
              <Alert variant="danger" title="Fill out all required fields before continuing." isInline />
            </FormAlert>
          )
        }
        <FormGroup label="Name" isRequired fieldId="name">
          <TextInput id="name" isRequired type="text" value={formData?.name || ""} onChange={(e) => setFormData({...formData, name: e.target.value})}></TextInput>
          <FormHelperText><HelperText><HelperTextItem variant={formErrors?.name ? "error" : "default"}>{ formErrors?.name }</HelperTextItem></HelperText></FormHelperText>
        </FormGroup>
        <FormGroup label="Description" fieldId="description">
          <TextInput id="description" type="text" value={formData?.description || ""} onChange={(e) => setFormData({...formData, description: e.target.value})}></TextInput>
        </FormGroup>
        <FormGroup label="Points" isRequired fieldId="points">
          <TextInput id="points" type="text" value={formData?.points || ""} onChange={(e) => setFormData({...formData, points: e.target.value})}></TextInput>
          <FormHelperText><HelperText><HelperTextItem variant={formErrors?.points ? "error" : "default"}>{ formErrors?.points }</HelperTextItem></HelperText></FormHelperText>
        </FormGroup>
        <FormGroup label="Icon" fieldId="icon">
          {/* <TextInput id="icon_url" type="text" value={formData?.icon_url || ""} onChange={(e) => setFormData({...formData, icon_url: e.target.value})}></TextInput> */}

          <SingleFileUploadComponent
            id="icon"
            accept={{ "image/*": ['.png', '.jpg', '.svg'] }}
            filename={formData?.icon_url || ""}
            setFilename={(e) => { if (debug) localLog("update file: " + JSON.stringify(e, null, 2)); setFormData({...formData, icon_url: e}) }}
            value={pictureFiledata}
            setValue={setPictureFiledata}
          />

        </FormGroup>
      </Form>
    </Modal>
  );
};

import * as React from "react";
import { Button } from "@patternfly/react-core";
import { FormHelperText } from "@patternfly/react-core";
import { Gallery, GalleryItem } from "@patternfly/react-core";
import { HelperText, HelperTextItem } from "@patternfly/react-core";
import { Modal, ModalBody, ModalFooter, ModalHeader, ModalVariant } from "@patternfly/react-core/next";
import { Split, SplitItem } from "@patternfly/react-core";
import { Stack, StackItem } from "@patternfly/react-core";
import { Text, TextArea, TextContent } from "@patternfly/react-core";

import { formatDistanceToNow } from "date-fns";
import { get, post } from "../../api/api";
import { dynamicSort, localLog } from "../../utils/Utilities";
import { NotificationContext } from "../../common/contexts/NotificationContext";
import { OrganizationContext } from "../../common/contexts/OrganizationContext";
import { UserContext } from "../../common/contexts/UserContext";
import { AvatarComponent } from "../../components/AvatarComponent";
import { ShoutOutCommentComponent } from "./ShoutOutCommentComponent";
import { ShoutOutModalCategoriesComponent } from "./ShoutOutModalCategoriesComponent";
import { ConfirmationModal } from "../../modals/ConfirmationModal";

export const ShoutOutModal = ({ shoutout, setShoutouts, isOpen, onClose }) => {

  const { addNotification } = React.useContext(NotificationContext);
  const organization = React.useContext(OrganizationContext);
  const user = React.useContext(UserContext);

  const [debug] = React.useState(false);
  const [errors, setErrors] = React.useState({});
  const [comment, setComment] = React.useState("");
  const [comments, setComments] = React.useState(shoutout.shoutout_comments);
  const [commentHelperText, setCommentHelperText] = React.useState("0/255 characters");
  const [isYesNoOpen, setIsYesNoOpen] = React.useState(false);

  const onDeleteComment = (id) => {
    onYesNoToggle();
  };

  const onYesNoToggle = () => {
    setIsYesNoOpen(!isYesNoOpen);
  };

  const refreshShoutOut = async () => {
    try {
      const response = await get(organization.id, "/shoutout/view/" + shoutout.id );
      setShoutouts((prevShoutouts) =>
        prevShoutouts.map((shoutout) =>
          shoutout.id === response.data.id ? response.data : shoutout
        )
      );
    } catch (error) {
      console.error(JSON.stringify(error));
    }
  };

  React.useEffect(() => {
    if (debug) localLog("shoutout.shoutout_comments: " + JSON.stringify(shoutout.shoutout_comments));
    const sorted = shoutout.shoutout_comments.sort(dynamicSort("-id"));
    if (debug) localLog("sorted: " + JSON.stringify(sorted));
    setComments(sorted);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shoutout]);

  const onCommentChange = (value) => {
    if (value.length > 0) {
      if (value.length === 1) {
        setComment(value);
        setCommentHelperText("1/255 character");
      } else {
        setComment(value);
        setCommentHelperText(`${value.length}/255 characters`);
      }
    } else {
      setComment(value);
      setCommentHelperText("0/255 characters");
    }
  };
    
  const handleCommentSubmit = async () => {
    const err = {};
    if (comment.length === 0) {
      err.comment = "The comment is a required field.";
      setErrors(err);
      return;
    }
    if (comment.length > 255) {
      err.comment = "The comment can only be 255 characters.";
      setErrors(err);
      return;
    }
    const body = {
      comment: comment,
      user_id: user.id,
      shoutout_id: shoutout.id,
      points: 10, // to be changed
    };
    try {
      // eslint-disable-next-line no-unused-vars
      const response = await post(organization.id, "/shoutoutInteraction/addComment", body);
      setComment("");
      refreshShoutOut();
      addNotification({ variant: 'success', title: 'Success', description: 'You added a comment to a ShoutOut.' });
    } catch (error) {
      console.error(JSON.stringify(error));
      addNotification({ variant: 'danger', title: 'Error Adding Comment', description: `${JSON.stringify(error?.message)}`});
    }
  };

  return (
    <>
    <Modal
      aria-label="ShoutOut Modal"
      variant={ModalVariant.medium}
      title="ShoutOut"
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalHeader title="ShoutOut" />
      <ModalBody>
        <Stack hasGutter>
          <StackItem>
            <Split hasGutter>
              <SplitItem>
                <AvatarComponent user={shoutout?.toUser} size="lg" />
              </SplitItem>
              <SplitItem isFilled>
                <TextContent>
                  <Text component="h1" style={{ marginBottom: '0px' }}>{`${shoutout.toUser.first_name} ${shoutout.toUser.surname}`}</Text>
                  <Text component="h2" style={{ marginTop: '0px' }}>{shoutout.toUser.user_role}</Text>
                </TextContent>
              </SplitItem>
              <SplitItem>
                <Stack>
                  <StackItem>
                    <TextContent>
                      <Text>
                        From:{" "} {shoutout.external_shoutout_YN ? shoutout.external_user_full_name : `${shoutout.fromUser.first_name} ${shoutout.fromUser.surname}`}
                      </Text>
                    </TextContent>
                  </StackItem>
                  <StackItem>
                    { formatDistanceToNow(new Date(shoutout.submitted_date), { addSuffix: true })}
                  </StackItem>
                </Stack>
              </SplitItem>
            </Split>
          </StackItem>
          <StackItem>
            <TextContent>
              <Text component="p">{shoutout.comment}</Text>
            </TextContent>
          </StackItem>
          <StackItem>
            <ShoutOutModalCategoriesComponent shoutout={shoutout} />
          </StackItem>
          <StackItem>
            <TextContent>
              <Text component="b"><Text component="i">New Comment</Text></Text>
            </TextContent>
            <TextArea
              id="comment"
              autoResize={true} 
              isRequired
              autoFocus
              placeholder="Write a comment..."
              resizeOrientation="none"
              rows={3}
              value={comment}
              onChange={(e) => onCommentChange(e.target.value)}
            />
            <FormHelperText>
              <HelperText style={{ float: 'right' }}>
                <HelperTextItem variant={'default'}>
                  <Text component="small">{ commentHelperText }</Text>
                </HelperTextItem>
              </HelperText>
              <HelperText>
                <HelperTextItem variant={ Object.keys(errors).length > 0 ? "error" : "default"}>
                  <Text component="small">{ errors.comment }</Text>
                </HelperTextItem>
              </HelperText>
            </FormHelperText>
          </StackItem>
          <StackItem>
            <TextContent>
              <Text component="b"><Text component="i">Comments</Text></Text>
            </TextContent>
          </StackItem>
          <StackItem>
            <Gallery hasGutter minWidths={{ default: '100%' }}>
            {
              comments.length > 0 ? (
                <>
                  {
                    comments.map((comment, index) => (
                      <GalleryItem>
                        <ShoutOutCommentComponent key={comment.id} comment={comment} onDelete={onDeleteComment} />
                      </GalleryItem>
                    ))
                  }
                </>
              ) : (
                <TextContent>
                  <Text component="p">
                    No comments yet.
                  </Text>
                </TextContent>
              )
            }            
            </Gallery>
          </StackItem>
        </Stack>
        </ModalBody>
        <ModalFooter>
          <Button onClick={handleCommentSubmit}>
            Submit
          </Button>
        </ModalFooter>
    </Modal>
    <ConfirmationModal isOpen={isYesNoOpen} onClose={onYesNoToggle} />
    </>
  );
};

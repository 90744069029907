import * as React from "react";
import { Breadcrumb, BreadcrumbItem } from "@patternfly/react-core";
import { EmptyState, EmptyStateBody, EmptyStateHeader, EmptyStateIcon } from "@patternfly/react-core";
import { Icon } from "@patternfly/react-core";
import { PageBreadcrumb, PageGroup, PageSection, PageSectionVariants } from "@patternfly/react-core";
import { Text, TextContent } from "@patternfly/react-core";
import { ExclamationCircleIcon, HomeIcon } from "@patternfly/react-icons";

export const NotFound = ({ isFullHeight = true, title, message }) => {

  return (
    <>
      <PageGroup stickyOnBreakpoint={{ default: 'top' }}>
        <PageBreadcrumb>
          <Breadcrumb>
            <BreadcrumbItem to="/"><Icon><HomeIcon /></Icon>&nbsp;Dashboard</BreadcrumbItem>
            <BreadcrumbItem isActive><Icon><ExclamationCircleIcon /></Icon>&nbsp;Error</BreadcrumbItem>
          </Breadcrumb>
        </PageBreadcrumb>
        <PageSection variant={PageSectionVariants.light} isWidthLimited>
          <TextContent>
            <Text component="h1"><Icon><ExclamationCircleIcon /></Icon>&nbsp;Page Not Found</Text>
            <Text component="p">Click on the Dashboard link to return to the application.</Text>
          </TextContent>
        </PageSection>
      </PageGroup>
      <PageSection>
        <EmptyState isFullHeight={isFullHeight === undefined ? true : isFullHeight} >
          <EmptyStateHeader
            titleText={title ? title : "404 Page Not Found"}
            headingLevel="h1"
            icon={<EmptyStateIcon icon={ExclamationCircleIcon} color={"#AA0000"} />}
          />
          <EmptyStateBody>
            { message ? message : "The requested resource could not be found but may be available in the future. Subsequent requests by the client are permissible." }
          </EmptyStateBody>
        </EmptyState>
      </PageSection>
    </>
  );

};
import * as React from "react";
import { DropdownList, MenuToggle } from "@patternfly/react-core";
import { Select, SelectList, SelectOption, ToolbarGroup, ToolbarItem, ToolbarToggleGroup } from "@patternfly/react-core";
import { FilterIcon } from "@patternfly/react-icons";

import { get_clean } from "../api/api";

export const OrganizationToolbarToggleGroup = ({ organization, setOrganization }) => {

  const [organizations, setOrganizations] = React.useState([]);
  const [containerExpanded, setContainerExpanded] = React.useState(false);

  const fetchOrganizations = async () => {
    try {
      const response = await get_clean(`/organizations/getOrganizations/all`);
      setOrganizations(response.data);
    } catch (error) {
      console.error(JSON.stringify(error));
      setOrganizations([]);
    }
  };

  React.useEffect(() => {

    const firstFetch = async() => {
      fetchOrganizations();
    }

    firstFetch();

    // eslint-disable-next-line react-hooks/exhaustive-deps  
  }, []);

  const onContainerSelect = async (_event, selection) => {
    const org = organizations.find(item => item.name === selection);
    setOrganization(org);
    setContainerExpanded(false);
  };

  const onContainerToggle = () => {
    setContainerExpanded((prevState) => !prevState);
  };

  return (
    <ToolbarToggleGroup toggleIcon={<FilterIcon />} breakpoint="xl">
      <ToolbarGroup>
        <ToolbarItem variant="label">
          Organization
        </ToolbarItem>
        <ToolbarItem visibility={{ default: 'visible' }}>
          <Select
            aria-labelledby="competition"
            role="menu"
            onSelect={onContainerSelect}
            onOpenChange={(isOpen) => setContainerExpanded(isOpen)}
            selected={organization}
            isOpen={containerExpanded}
            toggle={(toggleRef) => (
              <MenuToggle
                ref={toggleRef}
                onClick={onContainerToggle}
                isExpanded={containerExpanded}
                style={{ width: '250px' }}
              >
                { organization?.name }
              </MenuToggle>
            )}
          >
            <DropdownList>
              <SelectList>
                {
                  organizations.map((item) => {
                    return (
                      <SelectOption key={item.name} value={item.name} isSelected={organization?.name === item.name}>
                        { item.name }
                      </SelectOption>
                    )
                  })
                }
              </SelectList>
            </DropdownList>
          </Select>
        </ToolbarItem>
      </ToolbarGroup>
    </ToolbarToggleGroup>
  );

}
import * as React from "react";
import { Badge, Button, DataListAction, DataListCell, DataListItem, DataListItemCells, DataListItemRow, Icon } from "@patternfly/react-core";
import { Flex, FlexItem, Split, SplitItem, Stack, StackItem, Text, TextContent, TextVariants } from "@patternfly/react-core";
import { CommentIcon, ThumbsUpIcon } from "@patternfly/react-icons";
import { formatDistanceToNow } from "date-fns";
import { post } from "../../api/api";
import { AvatarComponent } from "../../components/AvatarComponent";
import { ShoutOutModal } from "./ShoutOutModal";

import "./ShoutOutDataListItem.css";

export const ShoutOutDataListItem = ({ user, organization, shoutout, setShoutouts }) => {

  const [liked, setLiked] = React.useState(false);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  // eslint-disable-next-line no-unused-vars
  const [numLikes, setNumLikes] = React.useState(shoutout.shoutout_likes.length);

  const onToggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const onClose = () => {
    onToggleModal();
  };

  const onOpen = () => {
    setIsModalOpen(true);
  };

  React.useEffect(() => {
    // Check if the user has already liked the shoutout
    const hasLiked = shoutout.shoutout_likes.some(like => like.user_id === user.id);
    setLiked(hasLiked);
  }, [shoutout, user]);

  const handleLike = async () => {
    try {
      const newLikedState = !liked;
      // Update likes count based on the new like state
      setNumLikes(prevNumLikes => newLikedState ? prevNumLikes + 1 : Math.max(prevNumLikes - 1, 0));
      setLiked(newLikedState);
      const likeBody = {
        user_id: user.id,
        shoutout_id: shoutout.id,
        points: newLikedState ? 10 : 0,
      };
      await post(organization.id, "/shoutoutInteraction/addLike", likeBody);
    } catch (error) {
      console.error(JSON.stringify(error));
    }
  };

  const renderCategory = (category) => (
    <Split>
      <SplitItem>
        <img src={category.icon_url} alt={category.name} style={{ height: '24px' }} />
      </SplitItem>
      <SplitItem>
        <Badge>{category.name.toUpperCase()}</Badge>
      </SplitItem>
    </Split>
  );

  return (
    <React.Fragment>
      <DataListItem aria-label="ShoutOuts">
      <DataListItemRow key={shoutout.id}>
        <DataListItemCells 
          key={shoutout.id}
          style={{ paddingBottom: '10px' }}
          dataListCells={[
            <DataListCell key="avatar" width={1}>
              <Flex>
                <FlexItem>
                  <AvatarComponent user={shoutout?.toUser} size="xl" />
                </FlexItem>
              </Flex>
            </DataListCell>,
            <DataListCell key="details" isFilled={true} width={5}>
              <Flex>
                <FlexItem>
                  <Stack>
                    <StackItem>
                      <TextContent>
                        <Text component={TextVariants.h2}>
                          {`${shoutout.toUser.first_name} ${shoutout.toUser.surname}`}
                        </Text>
                      </TextContent>
                    </StackItem>
                    <StackItem>

                      <Split hasGutter>
                        <SplitItem>
                          <TextContent>
                            <Text component={TextVariants.p}>
                              received a
                            </Text>
                          </TextContent>
                        </SplitItem>
                        <SplitItem>
                          <Split hasGutter>
                            { shoutout.category1 && <SplitItem>{ renderCategory(shoutout.category1) }</SplitItem> }
                            { shoutout.category2 && <SplitItem>{ renderCategory(shoutout.category2) }</SplitItem> }
                            { shoutout.category3 && <SplitItem>{ renderCategory(shoutout.category3) }</SplitItem> }
                            { shoutout.category4 && <SplitItem>{ renderCategory(shoutout.category4) }</SplitItem> }
                          </Split>
                        </SplitItem>
                        <SplitItem style={{ marginLeft: '0px' }}>
                          {
                            "ShoutOut " + formatDistanceToNow(new Date(shoutout.submitted_date), { addSuffix: true }) + " from "
                          }
                          <span style={{ fontWeight: 'bold' }}>
                            {
                              shoutout.external_shoutout_YN
                              ? shoutout.external_user_full_name
                              : `${shoutout.fromUser.first_name} ${shoutout.fromUser.surname}`
                            }
                          </span>
                        </SplitItem>
                      </Split>
                    </StackItem>
                    <StackItem>
                      <TextContent>
                        <Text>
                            <div style={{ minHeight: '2ch', maxHeight: '3ch', minWidth: '40ch', maxWidth: '100ch', maxInlineSize: '100ch' }}>
                              { shoutout.comment }
                            </div>
{/*
                          <div style={{ minHeight: '100px', width: '100%' }}><Truncate content={shoutout.comment} /></div>
*/}
                        </Text>
                      </TextContent>
                    </StackItem>
                  </Stack>
                </FlexItem>
              </Flex>
            </DataListCell>
          ]}
        />
        <DataListAction id="shoutout-action" aria-label="Actions" aria-labelledby="shoutout-action">
          <Button variant="tertiary" countOptions={{ count: shoutout.shoutout_comments.length, isRead: false }} onClick={onOpen}><Icon><CommentIcon /></Icon>&nbsp;Comments</Button>
          <Button variant="tertiary" countOptions={{ count: shoutout.shoutout_likes.length, isRead: false }} onClick={handleLike}><Icon><ThumbsUpIcon /></Icon>&nbsp;{liked ? "Unlike" : "Like"}</Button>
        </DataListAction>
      </DataListItemRow>
      </DataListItem>

      <ShoutOutModal shoutout={shoutout} setShoutouts={setShoutouts} isOpen={isModalOpen} onClose={onClose} />
    </React.Fragment>
  );
};

import * as React from "react";
import { DataListAction, DataListCell, DataListItem, DataListItemCells, DataListItemRow } from "@patternfly/react-core";
import { Divider, Dropdown, DropdownItem, DropdownList, MenuToggle } from "@patternfly/react-core";
import { EditIcon, EllipsisVIcon, PackageIcon, TimesIcon } from "@patternfly/react-icons";

// "scoringPeriod":
// {
//   "id": 1,
//   "organization_id": 1,
//   "period_name": "Hard worker",
//   "date_from": "2024-10-04T10:13:48.260Z",
//   "date_to": "2024-10-04T10:13:48.260Z",
// }

export const CompetitionDataListItem = ({ competition, isOpen, setIsOpen, onSelect, onToggle, onUpdate, onDelete }) => {

  const dateFormat = (date/*: Date*/) => {
    return date.toLocaleDateString('en-ZA', { year: 'numeric', month: '2-digit', day: '2-digit' }).replace(/\//g, '-');
  };

  return (
    <DataListItem key={competition.id} aria-labelledby={competition.id}>
      <DataListItemRow>
        <DataListItemCells
          dataListCells={[
            <DataListCell key="icon" isFilled={false}><PackageIcon /></DataListCell>,
            <DataListCell key="period_name">{competition.period_name}</DataListCell>,
            <DataListCell key="date_from">{dateFormat(new Date(competition.date_from))}</DataListCell>,
            <DataListCell key="date_to">{dateFormat(new Date(competition.date_to))}</DataListCell>
          ]}
        />
        <DataListAction isPlainButtonAction>
          <Dropdown
            popperProps={{ position: 'right' }}
            onOpenChange={(isOpen) => { if (isOpen) setIsOpen(competition.id); else setIsOpen(0) }}
            onSelect={onSelect}
            isOpen={(isOpen === competition.id)}
            toggle={(toggleRef) => (
              <MenuToggle
                ref={toggleRef}
                isExpanded={isOpen === competition.id}
                onClick={() => onToggle(competition.id)}
                variant="plain"
                aria-label="Competition Item Action Menu"
              >
                <EllipsisVIcon aria-hidden="true" />
              </MenuToggle>
            )}
          >
            <DropdownList>
              <DropdownItem key="edit" icon={<EditIcon />} onClick={() => onUpdate(competition.id)}>Edit</DropdownItem>
              <Divider key="sep1"></Divider>
              <DropdownItem key="delete" icon={<TimesIcon />} onClick={() => onDelete(competition.id)}>Delete</DropdownItem>
            </DropdownList>
          </Dropdown>
        </DataListAction>
      </DataListItemRow>
    </DataListItem>
  );

};

import * as React from "react";
import { DatePicker, DropdownList, isValidDate, yyyyMMddFormat } from "@patternfly/react-core";
import { MenuToggle, Select, SelectList, SelectOption } from "@patternfly/react-core";
import { ToolbarGroup, ToolbarItem, ToolbarToggleGroup } from "@patternfly/react-core";
import { FilterIcon } from "@patternfly/react-icons";

import { get } from "../api/api";
import { getCurrentCompetition } from "../utils/Utilities";

export const CompetitionToolbarComponent = ({ organizationId, competition, setCompetition, startDate, setStartDate, endDate, setEndDate }) => {

  const [containerExpanded, setContainerExpanded] = React.useState(false);
  const [competitions, setCompetitions] = React.useState([]);
//  const [competition, setCompetition] = React.useState({});

  // const today = new Date();
  // const startDateDefault = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate());
  // const endDateDefault = new Date(today.getFullYear(), today.getMonth(), today.getDate());

  const toValidator = (date) => {
    if (!isValidDate(startDate)) {
      return "Invalid Start Date";
    }
    if (!isValidDate(date)) {
      return "Invalid Date";
    }
    const from = new Date(startDate.getFullYear(), startDate.getMonth() + 1, startDate.getDate(), 0, 0, 0, 0);
    const to = new Date(date.getFullYear(), date.getMonth() + 1, date.getDate(), 23, 59, 59, 999);
    return (
      to >= from ? "" : "The End Date must be after the Start Date"
    )
  };

  const onStartDateChange = (event, _value, _date) => {
    setStartDate(_date);
    if (isValidDate(_date)) {
      _date = new Date(_date.getFullYear(), _date.getMonth() + 1, _date.getDate());
      setEndDate(_date);
    } else {
      setEndDate(null);
    }
  };

  const onEndDateChange = (event, value, _date) => {
    if (isValidDate(_date)) {
      setEndDate(_date);
    }
  };

  const fetchCompetitions = async () => {
    try {
      const response = await get(organizationId, `/scoringPeriod/getAllScoringPeriods`);
      setCompetitions(response.data.scoringPeriods);
    } catch (error) {
      console.error(JSON.stringify(error));
      setCompetitions([]);
    }
  };

  React.useEffect(() => {

    const firstFetch = async() => {
      fetchCompetitions();
    }

    firstFetch();

    // eslint-disable-next-line react-hooks/exhaustive-deps  
  }, []);

  React.useEffect(() => {
    // setStartDate(startDateDefault);
    // setEndDate(endDateDefault);

    let comp = getCurrentCompetition(competitions);
    if (competitions.find((item) => item === comp) === null) {
      setCompetitions({ ...competitions, comp });
    };
    if (comp === undefined) {
      comp = {
        period_name: "None",
        date_from: yyyyMMddFormat(new Date()),
        date_to: yyyyMMddFormat(new Date())
      }
    }
    setCompetition(comp);
    setStartDate(new Date(comp.date_from.substring(0, 10)));
    setEndDate(new Date(comp.date_to.substring(0, 10)));
  // eslint-disable-next-line react-hooks/exhaustive-deps  
  }, [competitions]);

  const onContainerSelect = async (_event, selection) => {
    const comp = competitions.find(item => item.period_name === selection);
    setCompetition(comp);
    await setStartDate(new Date(comp.date_from.substring(0, 10) + " 00:00:00"));
    await setEndDate(new Date(comp.date_to.substring(0, 10) + " 23:59:59"));
    setContainerExpanded(false);
  };

  const onContainerToggle = () => {
    setContainerExpanded((prevState) => !prevState);
  };

  return (
    <ToolbarToggleGroup toggleIcon={<FilterIcon />} breakpoint="xl">
      <ToolbarGroup variant="filter-group">
        <ToolbarItem id="competition" variant="label">
          Competition
        </ToolbarItem>
        <ToolbarItem visibility={{ default: 'visible' }}>
          <Select
            aria-labelledby="competition"
            role="menu"
            onSelect={onContainerSelect}
            onOpenChange={(isOpen) => setContainerExpanded(isOpen)}
            selected={competition}
            isOpen={containerExpanded}
            toggle={(toggleRef) => (
               <MenuToggle
                ref={toggleRef}
                onClick={onContainerToggle}
                isExpanded={containerExpanded}
                style={{ width: '250px' }}
              >
                 { competition?.period_name }
               </MenuToggle>
             )}
          >
            <DropdownList>
              <SelectList>
                {
                  competitions.map((item) => {
                    return (
                      <SelectOption key={item.period_name} value={item.period_name} isSelected={competition?.period_name === item.period_name}>
                        { item.period_name }
                      </SelectOption>
                    )
                  })
                }
              </SelectList>
            </DropdownList>
          </Select>
        </ToolbarItem>
      </ToolbarGroup>
      <ToolbarGroup variant="filter-group">
        <ToolbarItem>
          <DatePicker
            aria-labelledby="startdate"
            aria-label="Start Date"
            placeholder="YYYY-MM-DD"
            requiredDateOptions={{ isRequired: true, emptyDateText: "Date is required" }}
            value={startDate ? yyyyMMddFormat(startDate) : ""}
            onChange={onStartDateChange}
            isDisabled={true}
          />
        </ToolbarItem>
      </ToolbarGroup>
      <ToolbarGroup variant="filter-group">
        <ToolbarItem variant="label" visibility={{ default: 'hidden' }}>-</ToolbarItem>
        <ToolbarItem>
          <DatePicker
            aria-label="End Date"
            invalidFormatText="Invalid Format"
//            isDisabled={!isValidDate(startDate)}
            isDisabled={true}
            placeholder="YYYY-MM-DD"
            rangeStart={startDate}
            validators={[toValidator]}
            value={endDate ? yyyyMMddFormat(endDate) : ""}
            onChange={onEndDateChange}
          />
        </ToolbarItem>
      </ToolbarGroup>
    </ToolbarToggleGroup>
  );

}
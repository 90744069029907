import * as React from "react";
import { Dropdown, DropdownItem, DropdownList, MenuToggle } from "@patternfly/react-core";
import { NotificationDrawerListItem, NotificationDrawerListItemBody, NotificationDrawerListItemHeader } from "@patternfly/react-core";
import { EllipsisVIcon } from "@patternfly/react-icons";

import { OrganizationContext } from "./common/contexts/OrganizationContext";
import { UserContext } from "./common/contexts/UserContext";
import { put } from "./api/api";
import { localLog } from "./utils/Utilities";

// {
//   "id": 13,
//   "user_id": 1,
//   "has_seen_YN": 0,  -- 0:Unread, 1:Read
//   "has_interacted_YN": 0,
//   "notification_subject": "Shoutout",
//   "notification_text": "Congrats, you have received a new Shoutout"
// }

export const NotificationItem = ({ key, notification, update }) => {

  const organization = React.useContext(OrganizationContext);
  const user = React.useContext(UserContext);

  const [debug] = React.useState(false);
  const [isActionMenuOpen, setIsActionMenuOpen] = React.useState(false);

  const closeActionMenu = () => {
    setIsActionMenuOpen(false);
  };

  const onActionMenuClick = () => {
    setIsActionMenuOpen(!isActionMenuOpen);
  };

  const onListItemClick = () => {
    if (notification.isNotificationRead !== undefined) {
      notification.isNotificationRead = true;
    } else if (notification.has_seen_YN === 0) {
      notification.has_seen_YN = 1
      onUpdateNotification();
      update(notification);
    }
  };

  const onUpdateNotification = async () => {
    try {
      const response = await put(organization.id, "/notifications/users/" + user.id + "/notifications/" + notification.id, notification);
      if (debug) localLog("response: " + JSON.stringify(response, null, 2));
      closeActionMenu();
    } catch (error) {
      console.error(JSON.stringify(error));
    }
  };

  return (
    <NotificationDrawerListItem
      key={key}
      variant={notification.variant ? notification.variant : "info"}
      onClick={() => onListItemClick()}
      isRead={notification.isNotificationRead !== undefined ? notification.isNotificationRead : notification.has_seen_YN === 1}
    >
      <NotificationDrawerListItemHeader variant={notification.variant ? notification.variant : "info"} title={notification.title ? notification.title : notification.notification_subject} srTitle={notification.title ? notification.title : notification.notification_subject}>
        <Dropdown
          onSelect={onActionMenuClick}
          isOpen={isActionMenuOpen}
          id="notification-action-menu"
          onOpenChange={(isOpen) => !isOpen && closeActionMenu()}
          popperProps={{ position: 'right' }}
          toggle={(toggleRef) => (
            <MenuToggle
              ref={toggleRef}
              id="toggle-action-menu"
              aria-label="Notification Action Menu"
              variant="plain"
              onClick={() => onActionMenuClick()}
              isExpanded={isActionMenuOpen}
            >
              <EllipsisVIcon />
            </MenuToggle>
          )}
        >
          <DropdownList>
            <DropdownItem key="markasread">Mark as Read</DropdownItem>
          </DropdownList>
        </Dropdown>
      </NotificationDrawerListItemHeader>
      <NotificationDrawerListItemBody>
        { notification.description ? notification.description : notification.notification_text }
      </NotificationDrawerListItemBody>
    </NotificationDrawerListItem>
  );

};
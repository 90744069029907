import * as React from "react";
import { Bullseye, Split, SplitItem, Text, TextContent } from "@patternfly/react-core";

import { localLog } from "../../utils/Utilities";

export const ShoutOutModalCategoriesComponent = ({ shoutout }) => {

  const [debug] = React.useState(false);

  React.useEffect(() => {
    if (debug) localLog("shoutout: " + JSON.stringify(shoutout, null, 2));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shoutout]);

  return (
    <Split hasGutter>
    <SplitItem>
      <TextContent>
        <Text component="b"><Text component="i">Categories</Text></Text>
      </TextContent>
    </SplitItem>
    <SplitItem isFilled>
      <Bullseye>
        {
          shoutout.category1 && (
            <>
              <img 
                src={shoutout.category1?.icon_url.startsWith("https://") ? shoutout.category1?.icon_url : `https://shoutout-file-uploads.s3.af-south-1.amazonaws.com/${shoutout.category1?.icon_url}`}
                alt={shoutout.category1.name}
                width="24px"
                height="24px"
              />
              &nbsp;
              { shoutout.category1.name }
            </>
          )
        }
        &nbsp;
        {
          shoutout.category2 && (
            <>
              <img
                src={shoutout.category2?.icon_url.startsWith("https://") ? shoutout.category2?.icon_url : `https://shoutout-file-uploads.s3.af-south-1.amazonaws.com/${shoutout.category2?.icon_url}`}
                alt={shoutout.category2.name}
                width="24px"
                height="24px"
              />
              &nbsp;
              { shoutout.category2.name }
            </>
          )
        }
        &nbsp;
        { 
          shoutout.category3 && (
            <>
              <img
                src={shoutout.category3?.icon_url.startsWith("https://") ? shoutout.category3?.icon_url : `https://shoutout-file-uploads.s3.af-south-1.amazonaws.com/${shoutout.category3?.icon_url}`}
                alt={shoutout.category3.name}
                width="24px"
                height="24px"
              />
              &nbsp;
              { shoutout.category3.name }
            </>
          )
        }
        &nbsp;
        {
          shoutout.category4 && (
            <>
              <img
                src={shoutout.category4?.icon_url.startsWith("https://") ? shoutout.category4?.icon_url : `https://shoutout-file-uploads.s3.af-south-1.amazonaws.com/${shoutout.category4?.icon_url}`}
                alt={shoutout.category4.name}
                width="24px"
                height="24px"
              />
              &nbsp;
              { shoutout.category4.name }
            </>
          )
        }
      </Bullseye>
    </SplitItem>
  </Split>
  );

};
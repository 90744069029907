import * as React from "react";
import { Text, TextContent, TextVariants } from "@patternfly/react-core";
import { UserIcon } from "@patternfly/react-icons";
import { get } from "../api/api";
import { OrganizationContext } from "../common/contexts/OrganizationContext";

export const ManagerTextComponent = ({ user, showIcon }) => {

  const organization = React.useContext(OrganizationContext);
  const [manager, setManager] = React.useState("");

  React.useEffect(() => {

    const fetchManager = async() => {
      setManager("");
      if (!user)
        return;
      if (user.manager_id === null)
        return;
      try {
        const response = await get(organization.id, "/users/" + user.manager_id);
        setManager(response.data.first_name + " " + response.data.surname);
      } catch (error) {
        console.error(JSON.stringify(error));
      }
    };

    fetchManager();

  }, [user, organization.id]);

  return (
    <TextContent><Text component={TextVariants.p}>{ showIcon && manager.length > 0 ? (<UserIcon />) : ''}{ " " + manager }</Text></TextContent>
  );

}